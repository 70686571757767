import $store from '../store'
import { AuthService } from '@/services/auth.service'

/**
 * Current user state initialization
 * @WARN Must be always first in middleware chain
 */
export async function initCurrentUserStateMiddleware(to, from, next) {
    let url = location.href
    if (url.search('viraltrend.org') > -1) {
        if (url.search('/login') > -1) {
            location.href = 'https://vitisocial.com/login'
        } else if (url.search('/register') > -1) {
            location.href = 'https://vitisocial.com/register'
        } else {
            location.href = 'https://vitisocial.com'
        }
    }
    next()
}

/**
 * Check access permission to auth routes
 */
export function checkAccessMiddleware(to, from, next) {
    // const currentUserId = $store.state.user.currentUser.id
    const requiresAuth = to.matched.some(item => item.meta.requiresAuth)

    // if (isAuthRoute && currentUserId) return next()
    // if (isAuthRoute) return next({ name: 'login' })

    let accessToken = $store.getters['auth/accessToken']
    let currentUser = $store.getters['user/currentUser']
    let isAuthRoute = 'true'
    if (location.href.search("/dashboard") > -1) {
        isAuthRoute = 'false'
    }
    console.log('=======', isAuthRoute, accessToken, currentUser)
    if (requiresAuth == false) {
        $store.commit("auth/CLEAR_ACCESS_TOKEN");
        $store.commit("auth/CLEAR_CURRENT_USER");
    }
    if (isAuthRoute == 'false') {
        if (!accessToken || !currentUser) {
            $store.commit("auth/CLEAR_ACCESS_TOKEN");
            $store.commit("auth/CLEAR_CURRENT_USER");
            return location.href = '/login'
        }
    }
    next()
}

export function setPageTitleMiddleware(to, from, next) {
    const pageTitle = to.matched.find(item => item.meta.title)

    if (pageTitle) window.document.title = pageTitle.meta.title
    next()
}

export function scrollToTop(to, from, next) {
    window.scrollTo(0, 0)
    next()
}