<template>
    <section class="body-sign">
        <div class="center-sign">
            <a href="/" class="logo pull-left">
                <img src="/images/logo.png" height="54" alt="ViralTrend &dash; Go Viral" />
            </a>

            <div class="panel panel-sign">
                <div class="panel-title-sign mt-xl text-right">
                    <h2 class="title text-uppercase text-bold m-none"><i class="fa fa-user mr-xs"></i> Reset Password</h2>
                </div>
                <div class="panel-body">
                    <div id="">
                        <template v-if="stage == '1'">
                            <div class="form-group mb-lg">
                                <div class="col-sm-12 viral-col">
                                    <div class="input-group">
                                        <span class="input-group-addon viral-input-addon">
                                            <i class="fa fa-envelope"></i>
                                        </span>
                                        <input type="email" name="email" v-model="email" class="form-control input-lg" placeholder="E-mail Address" required/>
                                    </div>
                                </div>
                            </div>
                            <div class="row text-center viral-reg-btn-div">
                                <button @click="resetPassword" type="submit" class="mb-xs mt-xs mr-xs btn btn-lg btn-primary viral-btn text-center">
                                    Reset Password
                                </button>
                            </div>
                        </template>
                        <template v-if="stage== '2'">
                            <div class="form-group mb-lg">
                                <div class="col-sm-12 viral-col">
                                    <div class="input-group">
                                        <span class="input-group-addon viral-input-addon">
                                            <i class="fa fa-envelope"></i>
                                        </span>
                                        <input type="text" name="code" v-model="code" class="form-control input-lg" placeholder="Code sent to E-mail Address" required/>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-lg">
                                <div class="col-sm-12 viral-col">
                                    <div class="input-group">
                                        <span class="input-group-addon viral-input-addon">
                                            <i class="fa fa-key"></i>
                                        </span>
                                        <input type="password" v-model="password" name="password" class="form-control input-lg" placeholder="Password" required/>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-lg">
                                <div class="col-sm-12 viral-col">
                                    <div class="input-group">
                                        <span class="input-group-addon viral-input-addon">
                                            <i class="fa fa-key"></i>
                                        </span>
                                        <input type="password" v-model="repeat_password" name="repeat_password" class="form-control input-lg" placeholder="Repeat Password" required/>
                                    </div>
                                </div>
                            </div>
                            <div class="row text-center viral-reg-btn-div">
                                <button @click="changePassword" type="submit" class="mb-xs mt-xs mr-xs btn btn-lg btn-primary viral-btn text-center">
                                    Change Password
                                </button>
                            </div>
                        </template>

                        <p class="text-center" >
                            Remember password, Login? <a :href="'login'">Login!</a>
                        </p>
                                
                    </div>
                </div>


            </div>

            <p class="text-center text-muted mt-md mb-md" >
                &copy; Copyright {{ copyright() }},  
                <span>
                    <a href="https://viraltrend.org">ViralTrend</a>
                </span>
            </p>
        </div>
    </section>
</template>

<script>
import { ApiService } from '@/services/api.service'

export default {
  name: 'ResetPassword',
  data () {
    return {
      email: '',
      code: '',
      password: '',
      repeat_password: '',
      stage: '1',
      error: ''
    }
  },
  components: {
  },
  mounted(){
    let accessToken = this.$store.getters['auth/accessToken']
    if(accessToken){
      location.href = '/dashboard'
    }
  },
  methods: {
    async resetPassword () {
        if(!this.email){
            return this.$store.commit('toast/Notify', { type: 'warning', message: 'email is required' })
        }
        this.toggleProgress('show')
        let apiResponse = await ApiService.ResetPassword(this.email)
        // console.log(apiResponse)
        this.$store.commit('toast/Notify', { type: apiResponse['status'], message: apiResponse['message'] })
        if(apiResponse['status'] == 'success'){
            this.stage = '2'
        }
        this.toggleProgress('hide')
    },
    async changePassword () {
        if(!this.email){
            return this.$store.commit('toast/Notify', { type: 'warning', message: 'email is required' })
        }
        if(!this.code){
            return this.$store.commit('toast/Notify', { type: 'warning', message: 'code is required' })
        }
        if(!this.password){
            return this.$store.commit('toast/Notify', { type: 'warning', message: 'password is required' })
        }
        if(!this.repeat_password){
            return this.$store.commit('toast/Notify', { type: 'warning', message: 'repeat_password is required' })
        }
        this.toggleProgress('show')
        let apiResponse = await ApiService.ChangePassword(this.email,this.code,this.password,this.repeat_password)
        // console.log(apiResponse)
        this.$store.commit('toast/Notify', { type: apiResponse['status'], message: apiResponse['message'] })
        if(apiResponse['status'] == 'success'){
            setTimeout(() => {
                location.href = '/login'
            }, 1300);
        }
        this.toggleProgress('hide')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
