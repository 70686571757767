<template>
<section class="body complete-reg-body">

    <!-- start: header -->
    <header class="header">
        <div class="logo-container">
            <a href="/" class="logo">
                <img src="/images/logo.png" height="45" alt="ViralTrend" />
            </a>

        </div>

        <!-- start: search & user box -->
        <div class="header-right">

            <span class="separator"></span>

            <div id="userbox" class="userbox">
                <a href="#" data-toggle="dropdown">
                    <div class="profile-info" data-lock-name=" " data-lock-email="">
                        <span class="name">{{ currentUser['name'] }}</span>
                        <span class="role">Complete Registration</span>
                    </div>

                    <i class="fa custom-caret"></i>
                </a>

                <div class="dropdown-menu">
                    <ul class="list-unstyled">
                        <li class="divider"></li>

                        <li>
                            <a role="menuitem" tabindex="-1" href="javascript:;" @click="logout"><i class="fa fa-power-off"></i> Logout</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- end: search & user box -->
    </header>
    <!-- end: header -->

    <div class="inner-wrapper reg-complete">
        <div class="reg-complete-content">

            <div class="panel-body col-centered col-xs-10 col-sm-7 col-md-6 col-lg-5" id="w3">
                <h4 class="text-center">Complete Your Account</h4>
                <div class="wizard-progress">
                    <div class="steps-progress">
                        <div class="progress-indicator"></div>
                    </div>
                    <ul class="wizard-steps">
                        <li class="active">
                            <a href="#w3-plan" data-toggle="tab"><span>1</span>Select a Plan</a>
                        </li>
                        <li>
                            <a href="#w3-billing" data-toggle="tab"><span>2</span>Payment</a>
                        </li>
                    </ul>
                </div>
                <form class="" novalidate="novalidate" id="form" action="javascript:void(0)">
                    <div class="tab-content viral-complete-reg-content">
                        <div v-if="stage == 1" id="w3-plan" class="tab-pane active ">
                            <div class="form-group row viral-center-row">
                                <div class="col-sm-5 mb-sm mt-sm mr-sm ml-sm  viral-plans ">
                                    <header class=" text-center viral-plan-head-one">
                                        <h2 class="">Basic</h2>
                                        <p class="text-center"><strong>{{ currencySymbol(currency) }}{{ formatAmount(plan['basic_plan']) }}</strong>&nbsp;</p>
                                    </header>
                                    <div class="input-group">

                                        <ul class="input-group-addon viral-plan-info">
                                            <li>Earn from social media tasks</li>
                                            <li>{{ plan['basic_plan_referral'] }}% direct referral commission</li>
                                        </ul>
                                    </div>
                                    <div class="col-sm-12">
                                        <a @click="setCurrentPlan('basic')" class="mb-xs mt-sm mr-xs btn btn-block btn-primary  viral-btn text-center" rel="tooltip" data-placement="bottom" data-original-title="Select Basic Plan" id="basic">
                                            <span v-if="selected_plan == 'basic'">Selected</span>
                                            <span v-else>Select</span>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-sm-5 mb-sm mt-sm mr-sm ml-sm viral-plans ">
                                    <header class=" text-center viral-plan-head-two">
                                        <h2 class="">Premium</h2>
                                        <p class=""><strong>{{ currencySymbol(currency) }}{{ formatAmount(plan['premium_plan']) }}</strong>&nbsp;</p>
                                    </header>
                                    <div class="input-group">
                                        <ul class="input-group-addon viral-plan-info">
                                            <li>Earn from social media tasks.</li>
                                            <li>{{ plan['premium_plan_referral'] }}% direct referral commission.</li>
                                        </ul>
                                    </div>
                                    <div class="col-sm-12">
                                        <a @click="setCurrentPlan('premium')" class="mb-xs mt-sm mr-xs btn btn-block btn-primary viral-btn text-center" rel="tooltip" data-placement="bottom" data-original-title="Select Premium Plan" id="premium">
                                            <span v-if="selected_plan == 'premium'">Selected</span>
                                            <span v-else>Select</span>
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div v-if="stage == 2" id="w3-billing" class="tab-pane active">
                            <div class="form-group row ">
                                <div class="col-sm-12 mb-xs mt-xs panel ">
                                    <div class="input-group panel-body link-social-item complete-payment-pay ">

                                        <span class="input-group-addon white-bg ">
                                            <strong>
                                                <span id="plan" class="text-capitalize">{{ selected_plan }} Plan</span>
                                                / {{ currencySymbol(currency) }} 
                                                <span id="amount" v-if="selected_plan == 'basic'">{{ formatAmount(plan['basic_plan']) }}</span>
                                                <span id="amount" v-if="selected_plan == 'premium'">{{ formatAmount(plan['premium_plan']) }}</span>
                                            </strong>
                                        </span>
                                        <span class="" @click="hideRaveButton">
                                            <!-- <a class="mb-xs mt-xs mr-xs btn btn-primary btn-md viral-btn text-center pull-right" id="payBM">Pay With Rave</a> -->
                                            <pay-with-rave title="Pay With Rave" :email="currentUser.email" :phone="currentUser.phone" :name="currentUser.name" :amount="parseFloat(amount)" :currency="currency" :description="description" @paymentCallback="paymentCallback"></pay-with-rave>
                                        </span>
                                        <span class="rave-button-loader" style="display:none">
                                            <i class="fa fa-spin fa-spinner fa-2x"></i>
                                        </span>
                                    </div>
                                    <div class="text-center col-centered">
                                    </div>
                                    <div class="text-center">
                                        <br /><br />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <ul class="pager">
                        <li v-if="stage <= 1" class="previous disabled" @click="subscriptionStage(stage-1)">
                            <a class=""><i class="fa fa-angle-left"></i> Previous</a>
                        </li>
                        <li v-else class="previous mouse-pointer" @click="subscriptionStage(stage-1)">
                            <a class=""><i class="fa fa-angle-left"></i> Previous</a>
                        </li>
                        <li v-if="stage == 2" class="finish pull-right mouse-pointer">
                            <a>Finish</a>
                        </li>
                        <li v-if="stage == 1" class="next mouse-pointer" @click="subscriptionStage(stage+1)">
                            <a>Next <i class="fa fa-angle-right"></i></a>
                        </li>
                    </ul>
                </form>

            </div>
        </div>
    </div>
</section>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
import PayWithRave from '@/components/PayWithRave'

export default {
    name: 'CompleteRegistration',
    components: {
        PayWithRave
    },
    data() {
        return {
            user: '',
            currentUser: {},
            thisUser: {},
            plan: '',
            amount: '',
            currency: '',
            selected_plan: '',
            description: 'plan subscription',
            selected_plan: '',
            showRave: true,
            stage: 1,
            toReceive: 0,
            page: 0,
            per_page: 25
        }
    },
    mounted: function () {
        this.thisUser = this.$store.getters["user/currentUser"];
        this.currentUser = this.thisUser
        this.id = this.thisUser['id']
        this.getPlans()
    },
    methods: {
        async getPlans() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetPlans();
            // console.log('GetMe',apiResponse)
            if (apiResponse['status'] == 'success') {
                this.plan = apiResponse['plan']
                this.wallet = apiResponse['data']
                this.currency = apiResponse['currency']
                this.currentUser = apiResponse['user']
            }
            this.toggleProgress('hide')
        },
        subscriptionStage(a){
            if(a == 2){
                if(!this.selected_plan){
                    this.$store.commit("toast/Notify", {
                        type: 'warning',
                        message: 'You need to select a plan',
                    });
                    return
                }
            }
            this.stage = a
        },
        setCurrentPlan(a){
            if(a == 'basic'){
                this.amount = this.plan['basic_plan']
            }else if(a == 'premium'){
                this.amount = this.plan['premium_plan']
            }
            this.selected_plan = a
        },
        hideRaveButton(){
            $("#rave-button").hide()
            $("#rave-button-loader").show()
        },
        async paymentCallback(response){
            this.$store.commit("toast/Notify", {
                type: 'success',
                message: 'Payment Received, Please Hold On so we Boost the Ad',
            });
            this.toggleProgress('show')
            let txRef = response['tx']['txRef'];
            if (txRef) {
                this.toggleProgress('show')
                this.transaction_id = txRef
                let apiResponse = await ApiService.UserSubscribe(this.amount, this.selected_plan, this.transaction_id);
                this.$store.commit("toast/Notify", {
                    type: apiResponse['status'],
                    message: apiResponse['message'],
                });
                if(apiResponse && apiResponse['status'] == 'success'){
                    this.$store.commit('user/SAVE_CURRENT_USER_SUBSCRIPTION', apiResponse['subscription'])
                    setTimeout(() => {
                        location.href = '/dashboard'
                    }, 1300);
                }
                this.toggleProgress('hide')
            } else {
                this.$toastr.w('Warning', 'Transaction reference could not be found')
            }
            this.toggleProgress('hide')
        },
        logout() {
            this.$store.commit("auth/CLEAR_ACCESS_TOKEN");
            location.href = "/login";
        }
    },
}
</script>

<styleold scoped>
html.dark .select2-container .select2-choices .select2-search-field input,
html.dark .select2-container .select2-choice,
html.dark .select2-container .select2-choices {
    color: #eee;
    background-color: #282d36;
    border-color: #282d36
}

html.dark .select2-search {
    position: relative
}

html.dark .select2-search input {
    color: #eee;
    background: #2e353e;
    border-color: #242830
}

html.dark .select2-search input.select2-active {
    background: #2e353e
}

html.dark .select2-search:before {
    font-family: fontawesome;
    content: "\f002";
    position: absolute;
    right: 15px;
    top: 50%;
    color: #eee;
    font-size: 12px;
    margin: -11px 0 0
}

html.dark .select2-drop {
    background-color: #282d36;
    border-color: #21262d
}

html.dark .select2-container-multi .select2-choices .select2-search-choice {
    color: #eee
}

html.dark .select2-container .select2-choice .select2-arrow,
html.dark .select2-container .select2-choice div {
    border-color: #282d36
}

html.dark .select2-results .select2-result-label {
    color: #eee
}

.panel-body .wizard-tabs {
    margin: -15px -15px 15px
}

.panel-body-nopadding .wizard-tabs {
    margin: 0
}

.wizard-tabs ul {
    display: table;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none
}

.wizard-tabs ul>li {
    display: table-cell;
    border-bottom: 1px solid #eee
}

.wizard-tabs ul>li.active {
    border-bottom-color: transparent
}

.wizard-tabs ul>li.active>a,
.wizard-tabs ul>li.active>a:hover,
.wizard-tabs ul>li.active>a:focus {
    background: #fff;
    color: #555
}

.wizard-tabs ul>li.active>a:after {
    border-left-color: #fff
}

.wizard-tabs ul>li.active .badge {
    background-color: #ccc
}

.wizard-tabs ul>li.disabled>a,
.wizard-tabs ul>li.disabled>a:hover,
.wizard-tabs ul>li.disabled>a:focus {
    color: #ccc
}

.wizard-tabs ul>li.disabled .badge {
    background-color: #ccc
}

.wizard-tabs ul>li>a {
    position: relative;
    display: block;
    padding: 5px;
    font-size: 13px;
    font-size: 1.3rem;
    text-decoration: none;
    color: #555
}

.wizard-tabs ul>li>a,
.wizard-tabs ul>li>a:hover {
    background: #f4f4f4;
    color: #555
}

.wizard-tabs ul>li>a:hover {
    box-shadow: none
}

.wizard-tabs ul>li>a:active,
.wizard-tabs ul>li>a:focus {
    border-bottom: 0
}

.wizard-tabs ul>li>a:before,
.wizard-tabs ul>li>a:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    right: -10px;
    z-index: 1;
    border: 17px solid transparent;
    border-right: 0;
    border-left: 10px solid transparent;
    width: 0;
    height: 0
}

.wizard-tabs ul>li>a:before {
    z-index: 1;
    right: -11px;
    border-left-color: #e0e0e0
}

.wizard-tabs ul>li>a:after {
    z-index: 2;
    border-left-color: #f4f4f4
}

.wizard-tabs ul>li:last-child>a:before,
.wizard-tabs ul>li:last-child>a:after {
    display: none
}

html.dark .wizard-tabs ul>li {
    border-color: #282d36
}

html.dark .wizard-tabs ul>li.active {
    border-color: #2e353e
}

html.dark .wizard-tabs ul>li.active a,
html.dark .wizard-tabs ul>li.active a:hover,
html.dark .wizard-tabs ul>li.active a:active {
    background: #2e353e;
    color: #eee
}

html.dark .wizard-tabs ul>li.active a:before {
    border-left-color: #21262d
}

html.dark .wizard-tabs ul>li.active a:after {
    border-left-color: #2e353e
}

html.dark .wizard-tabs ul>li a,
html.dark .wizard-tabs ul>li a:hover {
    background: #282d36;
    color: #eee
}

html.dark .wizard-tabs ul>li a:before {
    border-left-color: #21262d
}

html.dark .wizard-tabs ul>li a:after {
    border-left-color: #282d36
}

html .wizard-progress,
html.dark .wizard-progress {
    margin: 0 15px
}

html .wizard-progress .steps-progress,
html.dark .wizard-progress .steps-progress {
    height: 2px;
    margin: 0 38px;
    position: relative;
    top: 15px;
    background: #ccc
}

html .wizard-progress .steps-progress .progress-indicator,
html.dark .wizard-progress .steps-progress .progress-indicator {
    height: 100%;
    width: 0;
    background: #ccc;
    -webkit-transition: width .2s ease-in;
    -moz-transition: width .2s ease-in;
    transition: width .2s ease-in
}

html .wizard-progress .wizard-steps,
html.dark .wizard-progress .wizard-steps {
    list-style: none;
    margin: 0;
    padding: 15px 0 0;
    display: inline-block;
    width: 100%;
    font-size: 0;
    text-align: justify;
    -ms-text-justify: distribute-all-lines
}

html .wizard-progress .wizard-steps:after,
html.dark .wizard-progress .wizard-steps:after {
    display: inline-block;
    width: 100%;
    content: '.';
    font-size: 0;
    height: 0;
    line-height: 0;
    visibility: hidden
}

html .wizard-progress .wizard-steps li,
html.dark .wizard-progress .wizard-steps li {
    display: inline-block;
    vertical-align: top;
    min-width: 50px;
    max-width: 100px
}

html .wizard-progress .wizard-steps li a,
html.dark .wizard-progress .wizard-steps li a {
    position: relative;
    display: block;
    padding: 25px 8px 0;
    font-size: 11px;
    color: #33333f;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    word-break: break-all
}

html .wizard-progress .wizard-steps li a span,
html.dark .wizard-progress .wizard-steps li a span {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    background: #ccc;
    color: #fff;
    line-height: 26px;
    text-align: center;
    margin-top: -15px;
    margin-left: -15px;
    width: 30px;
    height: 30px;
    border-radius: 35px;
    font-size: 13px;
    text-indent: -1px;
    border: 2px solid #ccc;
    -webkit-transition: all .2s ease-in;
    -moz-transition: all .2s ease-in;
    transition: all .2s ease-in
}

html .wizard-progress .wizard-steps li.completed a span,
html.dark .wizard-progress .wizard-steps li.completed a span {
    background: #ccc;
    color: #fff
}

html .wizard-progress .wizard-steps li.active a span,
html.dark .wizard-progress .wizard-steps li.active a span {
    background: #fff;
    color: #ccc;
    border-color: #ccc
}

html .wizard-progress .wizard-steps li.completed.active a span,
html.dark .wizard-progress .wizard-steps li.completed.active a span {
    color: #fff
}

html .wizard-progress.wizard-progress-lg,
html.dark .wizard-progress.wizard-progress-lg {
    margin: 0 auto 30px;
    width: 80%
}

html .wizard-progress.wizard-progress-lg .steps-progress,
html.dark .wizard-progress.wizard-progress-lg .steps-progress {
    margin: 0 52px;
    height: 4px;
    top: 34px
}

html .wizard-progress.wizard-progress-lg .wizard-steps,
html.dark .wizard-progress.wizard-progress-lg .wizard-steps {
    padding-top: 30px
}

html .wizard-progress.wizard-progress-lg ul li,
html.dark .wizard-progress.wizard-progress-lg ul li {
    max-width: 135px
}

html .wizard-progress.wizard-progress-lg ul li a,
html.dark .wizard-progress.wizard-progress-lg ul li a {
    padding-top: 40px;
    font-size: 14px
}

html .wizard-progress.wizard-progress-lg ul li a span,
html.dark .wizard-progress.wizard-progress-lg ul li a span {
    width: 60px;
    height: 60px;
    margin-top: -30px;
    margin-left: -30px;
    border-radius: 60px;
    line-height: 52px;
    font-size: 22px;
    border-width: 4px
}

html.dark .wizard-progress .wizard-steps li a {
    color: #808697
}

html.dark .wizard-progress.wizard-progress ul li a span {
    background: #242830;
    border-color: #242830
}

html.dark .wizard-progress .wizard-steps li.active a span {
    background: #242830
}

html.dark .wizard-progress .steps-progress {
    background: #242830
}

@media only screen and (max-width: 991px) {

    html .wizard-progress,
    html .wizard-progress.wizard-progress-lg,
    html.dark .wizard-progress,
    html.dark .wizard-progress.wizard-progress-lg {
        width: auto;
        margin-bottom: 0;
        margin-left: -15px;
        margin-right: -15px
    }

    html .wizard-progress .steps-progress,
    html.dark .wizard-progress .steps-progress {
        display: none
    }

    html .wizard-progress .wizard-steps,
    html.dark .wizard-progress .wizard-steps {
        display: block;
        font-size: 0;
        overflow: hidden;
        white-space: nowrap;
        text-align: left;
        width: 100%
    }

    html .wizard-progress .wizard-steps li,
    html.dark .wizard-progress .wizard-steps li {
        -webkit-transition: margin .5s linear;
        -moz-transition: margin .5s linear;
        transition: margin .5s linear;
        display: inline-block;
        float: none;
        position: relative;
        width: 100%;
        min-width: 0;
        max-width: none
    }

    html .wizard-progress .wizard-steps li a,
    html.dark .wizard-progress .wizard-steps li a {
        position: relative;
        z-index: 2
    }

    html .wizard-progress .wizard-steps li:before,
    html .wizard-progress .wizard-steps li:after,
    html.dark .wizard-progress .wizard-steps li:before,
    html.dark .wizard-progress .wizard-steps li:after {
        content: '';
        display: block;
        height: 2px;
        position: absolute;
        top: 0;
        width: 50%
    }

    html .wizard-progress .wizard-steps li:before,
    html.dark .wizard-progress .wizard-steps li:before {
        background: #ccc;
        left: 0
    }

    html .wizard-progress .wizard-steps li:after,
    html.dark .wizard-progress .wizard-steps li:after {
        background: #ccc;
        right: 0
    }

    html .wizard-progress .wizard-steps li.active,
    html.dark .wizard-progress .wizard-steps li.active {
        margin-left: 0
    }

    html .wizard-progress .wizard-steps li.completed,
    html.dark .wizard-progress .wizard-steps li.completed {
        margin-left: -100%
    }

    html .wizard-progress .wizard-steps li.completed:after,
    html.dark .wizard-progress .wizard-steps li.completed:after {
        background: #ccc
    }

    html .wizard-progress .wizard-steps li.completed.active,
    html.dark .wizard-progress .wizard-steps li.completed.active {
        margin-left: 0
    }

    html .wizard-progress .wizard-steps li:first-child:before,
    html.dark .wizard-progress .wizard-steps li:first-child:before {
        display: none
    }

    html .wizard-progress .wizard-steps li:last-child:after,
    html.dark .wizard-progress .wizard-steps li:last-child:after {
        display: none
    }

    html .wizard-progress.wizard-progress-lg .wizard-steps li,
    html.dark .wizard-progress.wizard-progress-lg .wizard-steps li {
        min-width: 0;
        max-width: none
    }

    html .wizard-progress.wizard-progress-lg .wizard-steps li:before,
    html .wizard-progress.wizard-progress-lg .wizard-steps li:after,
    html.dark .wizard-progress.wizard-progress-lg .wizard-steps li:before,
    html.dark .wizard-progress.wizard-progress-lg .wizard-steps li:after {
        height: 4px
    }
}

.form-wizard {
    margin-bottom: 20px
}

.form-wizard .tabs {
    margin-bottom: 0
}

.form-wizard .tab-content {
    background: 0 0;
    border: 0;
    box-shadow: none
}

.form-wizard ul.pager .next a,
.form-wizard ul.pager .previous a,
.form-wizard ul.pager .first a,
.form-wizard ul.pager .last a,
.form-wizard ul.pager .finish a {
    cursor: pointer
}

.form-wizard ul.pager .disabled a {
    cursor: not-allowed
}

html.dark .form-wizard .pager li>a,
html.dark .form-wizard .pager li>a:hover,
html.dark .form-wizard .pager li>a:focus,
html.dark .form-wizard .pager li>span {
    background: #2e353e;
    border-color: #282d36;
    color: #eee
}

html.dark .form-wizard .pager .disabled>a,
html.dark .form-wizard .pager .disabled>a:hover,
html.dark .form-wizard .pager .disabled>a:focus,
html.dark .form-wizard .pager .disabled>span {
    background: #282d36;
    border-color: #282d36;
    color: #eee
}

.switch {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    margin: 3px 0
}

.switch .ios-switch {
    height: 35px;
    width: 65px
}

.switch .ios-switch .handle {
    height: 31px;
    width: 31px
}

.switch.switch-primary .ios-switch .on-background {
    background: #ccc
}

.switch.switch-success .ios-switch .on-background {
    background: #47a447
}

.switch.switch-warning .ios-switch .on-background {
    background: #ed9c28
}

.switch.switch-danger .ios-switch .on-background {
    background: #d2322d
}

.switch.switch-info .ios-switch .on-background {
    background: #5bc0de
}

.switch.switch-dark .ios-switch .on-background {
    background: #171717
}

.switch.switch-lg .ios-switch {
    height: 45px;
    width: 75px
}

.switch.switch-lg .ios-switch .handle {
    height: 41px;
    width: 41px
}

.switch.switch-sm .ios-switch {
    height: 25px;
    width: 55px
}

.switch.switch-sm .ios-switch .handle {
    height: 21px;
    width: 21px
}

.ios-switch {
    height: 45px;
    width: 75px;
    position: relative;
    background-color: #e5e5e5;
    border-radius: 100px;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden
}

.ios-switch .background-fill {
    width: 100%;
    height: 100%;
    border-radius: 100px;
    position: absolute;
    left: 0;
    top: 0
}

.ios-switch .on-background {
    background-image: -o-linear-gradient(#00e459, #00e158);
    background-image: -ms-linear-gradient(#00e459, #00e158);
    background-image: -moz-linear-gradient(#00e459, #00e158);
    background-image: -webkit-linear-gradient(#00e459, #00e158);
    background-image: linear-gradient(#00e459, #00e158);
    opacity: 0;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    filter: alpha(opacity=0);
    z-index: 1;
    -o-transition: .3s .2s;
    -ms-transition: .3s .2s;
    -moz-transition: .3s .2s;
    -webkit-transition: .3s .2s;
    transition: .3s .2s
}

.ios-switch .state-background {
    border-radius: 100px;
    z-index: 2;
    background-image: -o-linear-gradient(white, #fdfdfd);
    background-image: -ms-linear-gradient(white, #fdfdfd);
    background-image: -moz-linear-gradient(white, #fdfdfd);
    background-image: -webkit-linear-gradient(white, #fdfdfd);
    background-image: linear-gradient(white, #fdfdfd);
    border: 2px solid #e5e5e5;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -o-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    -o-transition: .4s;
    -ms-transition: .4s;
    -moz-transition: .4s;
    -webkit-transition: .4s;
    transition: .4s
}

.ios-switch .handle {
    width: 41px;
    height: 41px;
    background-color: #fff;
    top: 2px;
    left: 2px;
    position: absolute;
    border-radius: 20px;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, .075), 0 3px 5px rgba(0, 0, 0, .15), 1px 2px 2px rgba(0, 0, 0, .05);
    z-index: 3;
    -o-transition: -o-transform .3s .25s cubic-bezier(.455, .03, .215, 1.33);
    -ms-transition: -ms-transform .3s .25s cubic-bezier(.455, .03, .215, 1.33);
    -moz-transition: -moz-transform .3s .25s cubic-bezier(.455, .03, .215, 1.33);
    -webkit-transition: -webkit-transform .3s .25s cubic-bezier(.455, .03, .215, 1.33);
    -o-transition: transform .3s .25s cubic-bezier(.455, .03, .215, 1.33);
    -ms-transition: transform .3s .25s cubic-bezier(.455, .03, .215, 1.33);
    -moz-transition: transform .3s .25s cubic-bezier(.455, .03, .215, 1.33);
    -webkit-transition: transform .3s .25s cubic-bezier(.455, .03, .215, 1.33);
    transition: transform .3s .25s cubic-bezier(.455, .03, .215, 1.33);
    -o-box-sizing: content-box;
    -ms-box-sizing: content-box;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box
}

.ios-switch.off .handle {
    -o-animation: expand-off .3s .2s;
    -ms-animation: expand-off .3s .2s;
    -moz-animation: expand-off .3s .2s;
    -webkit-animation: expand-off .3s .2s;
    animation: expand-off .3s .2s;
    -o-transform: translate(0px, 0);
    -ms-transform: translate(0px, 0);
    -moz-transform: translate(0px, 0);
    -webkit-transform: translate(0px, 0);
    transform: translate(0px, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0)
}

.ios-switch.off .on-background {
    -o-transition: .3s 0s;
    -ms-transition: .3s 0s;
    -moz-transition: .3s 0s;
    -webkit-transition: .3s 0s;
    transition: .3s 0s
}

.ios-switch.off .state-background {
    -o-transition: .4s .25s;
    -ms-transition: .4s .25s;
    -moz-transition: .4s .25s;
    -webkit-transition: .4s .25s;
    transition: .4s .25s
}

.ios-switch.on .handle {
    -o-animation: expand-on .3s .2s;
    -ms-animation: expand-on .3s .2s;
    -moz-animation: expand-on .3s .2s;
    -webkit-animation: expand-on .3s .2s;
    animation: expand-on .3s .2s;
    -o-transform: translate(30px, 0);
    -ms-transform: translate(30px, 0);
    -moz-transform: translate(30px, 0);
    -webkit-transform: translate(30px, 0);
    transform: translate(30px, 0);
    -o-transform: translate3d(30px, 0, 0);
    -ms-transform: translate3d(30px, 0, 0);
    -moz-transform: translate3d(30px, 0, 0);
    -webkit-transform: translate3d(30px, 0, 0);
    transform: translate3d(30px, 0, 0)
}

.ios-switch.on .on-background {
    opacity: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100)
}

.ios-switch.on .state-background {
    -o-transform: scale(0);
    -ms-transform: scale(0);
    -moz-transform: scale(0);
    -webkit-transform: scale(0);
    transform: scale(0)
}


.wizard-steps>li.active .badge {
    background-color: #023562
}

.wizard-steps>li.active a,.wizard-steps>li.active a:hover,.wizard-steps>li.active a:focus {
    border-top-color: #023562
}

.wizard-tabs ul>li.active .badge {
    background-color: #023562
}

html .wizard-progress .steps-progress .progress-indicator,html.dark .wizard-progress .steps-progress .progress-indicator {
    background: #023562
}

html .wizard-progress .wizard-steps li.completed a span,html.dark .wizard-progress .wizard-steps li.completed a span {
    border-color: #023562;
    background: #023562
}

html .wizard-progress .wizard-steps li.active a span,html.dark .wizard-progress .wizard-steps li.active a span {
    color: #023562;
    border-color: #023562
}
</styleold>
