export default {
    namespaced: true,
    state: {
        accessTokenExpDate: '',
        accessToken: ''
    },
    getters: {
        accessToken: state => {
            let token = localStorage.getItem('access_token')
            return token;
        },
        postCategories: state => {
            let postCategories = localStorage.getItem('post_categories')
            if (postCategories) {
                postCategories = JSON.parse(postCategories)
            }
            return postCategories;
        },
    },
    mutations: {
        SET_ATOKEN_EXP_DATE(state, expDate) {
            state.accessTokenExpDate = expDate
        },
        SET_ACCESS_TOKEN(state) {
            let token = localStorage.getItem('access_token')
            state.accessToken = token
        },
        SAVE_ACCESS_TOKEN(state, token) {
            state.accessToken = token
            localStorage.setItem('access_token', token)
        },
        SAVE_POST_CATEGORIES(state, categories) {
            localStorage.setItem('post_categories', JSON.stringify(categories))
        },
        CLEAR_ACCESS_TOKEN(state) {
            localStorage.clear('access_token')
        }
    }
}