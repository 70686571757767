<template>
<section role="main" class="content-body">
    <header class="page-header">
        <h2>Tasks</h2>
        <div class="right-wrapper pull-right">
            <ol class="breadcrumbs">
                <li><a href="dashboard.html"><i class="fa fa-home"></i></a></li>
            </ol>
        </div>
    </header>
    <div class="panel">
        <h2 class="create-advert-intro">Tasks</h2>
        <h4 class="text-bold ">Approve tasks that you have completed and view previously approved tasks.</h4>
        <div class="row  mt-lg">
            <div class="tabs ">
                <ul class="nav nav-tabs nav-justified adverts-history-tab">
                    <li class="active"><a href="#completed-advert-history" data-toggle="tab">Completed Tasks</a></li>
                    <li style="display: none;"><a href="#pending-advert-history" data-toggle="tab">Pending Tasks</a></li>
                </ul>
                <div class="tab-content">
                    <div id="completed-advert-history" class="tab-pane active table-responsive">
                        <table id="completed-task-table-details" class="table table-striped mb-none">
                            <thead>
                                <tr>
                                    <th>Task Name &nbsp;(<span>type</span>)</th>
                                    <th>URL/Link</th>
                                    <th>Reward</th>
                                    <th>Proof</th>
                                    <th>Status</th>
                                    <th>Created At</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="ut in userTasks">
                                    <td class="active-advert-td">
                                        <span v-if="ut.task">
                                            {{ ut.task.platform }} ({{ ut.task.type }})
                                        </span>
                                    </td>
                                    <td class="active-advert-td">
                                        <a v-if="ut.task" :href="ut.task.url" target="_blank">
                                            {{ ut.task.url }}
                                        </a>
                                    </td>
                                    <td class="active-advert-td">
                                        <span v-if="ut.task" class="pl-xs pr-xs pt-xs pb-xs label label-lg label-warning"><strong>
                                                ₦&nbsp;{{ ut.task.amount }}
                                            </strong>
                                        </span>
                                    </td>
                                    <td>
                                        <div v-if="ut['proof_of_task_url']">
                                            <a :href="ut['proof_of_task_url']" target="_blank">
                                                <img :src="ut['proof_of_task_url']" style="height:50px" />
                                            </a>
                                        </div>
                                    </td>
                                    <td>
                                        <span v-if="ut.status == 'pending'" class="label label-warning">Pending Submission</span>
                                        <span v-if="ut.status == 'pending_approval'" class="label label-warning">Pending Approval</span>
                                        <span v-if="ut.status == 'approved' || ut.status == 'complete'" class="label label-success">Paid</span>
                                        <span v-if="ut.status == 'denied'" class="label label-danger">Rejected</span>
                                        <div v-if="ut.status == 'denied'">
                                            {{ ut.reason }}
                                        </div>
                                    </td>
                                    <td class="active-advert-td"><span>{{ formatDateTimeNiceShort(ut.created_at) }}</span></td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="text-center" v-if="loading == true">
                            <i class="fa fa-spin fa-spinner fa-2x"></i>
                        </div>
                    </div>
                    <div>
                        <div class="row text-center" v-if="pagination">
                            <button class="btn btn-danger col" @click="previousPage">Previous</button> 
                            <button class="btn btn-default col">{{ pagination['current_page'] }}</button> 
                            <button class="btn btn-success col" @click="nextPage">Next</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'

export default {
    name: 'Wallet',
    components: {},
    data() {
        return {
            user: '',
            currentUser: {},
            thisUser: {},
            userTasks: '',
            toReceive: 0,
            pagination:'',
            loading: true,
            page: 1,
            per_page: 25
        }
    },
    mounted: function () {
        this.thisUser = this.$store.getters["user/currentUser"];
        this.currentUser = this.thisUser
        this.id = this.thisUser['id']
        this.getUserTasks()
    },
    methods: {
        async getUserTasks() {
            this.loading = true
            let apiResponse = await ApiService.GetUserTasks(this.page, this.per_page);
            if (apiResponse['status'] == 'success') {
                this.userTasks = apiResponse['data']
                this.pagination = apiResponse['pagination']
            }
            this.loading = false
        },
        nextPage(){
            let page = this.page
            let pagination = this.pagination
            if(page < pagination['last_page']){
                this.page = page+1
                this.getUserTasks()
            }else{
                this.$store.commit("toast/Notify", {
                    type: 'warning',
                    message: 'No next page',
                });
            }
        },
        previousPage(){
            let page = this.page
            if(page > 1){
                this.page = page-1
                this.getUserTasks()
            }else{
                this.$store.commit("toast/Notify", {
                    type: 'warning',
                    message: 'No previous page',
                });
            }
        }
    },
}
</script>

