<template>
<section class="body-sign">
    <div class="center-sign">
        <a href="/" class="logo pull-left">
            <img src="/images/logo.png" height="54" alt="ViralTrend &dash; Go Viral" />
        </a>

        <div class="panel panel-sign">
            <div class="panel-title-sign mt-xl text-right">
                <h2 class="title text-uppercase text-bold m-none"><i class="fa fa-user mr-xs"></i> Fast Log In</h2>
            </div>
            <div class="panel-body">
                <div class="text-center">
                    <i class="fa fa-spin fa-spinner fa-2x"></i>
                </div>
            </div>
        </div>

        <p class="text-center text-muted mt-md mb-md">
            &copy; Copyright {{ copyright() }},
            <span>
                <a href="https://viraltrend.org">ViralTrend</a>
            </span>
        </p>
    </div>
</section>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'

export default {
    name: 'AutoLogin',
    data() {
        return {
            access_token: ''
        }
    },
    mounted() {
        localStorage.clear()
        let params = this.$route.params
        this.access_token = params['key']

        this.$store.commit("auth/CLEAR_ACCESS_TOKEN");
        this.$store.commit("user/CLEAR_CURRENT_USER");
        this.$store.commit("user/CLEAR_CURRENT_USER_SUBSCRIPTION");

        this.autoLogin()
    },
    methods: {
        async autoLogin() {
            this.toggleProgress('show')
            let loginResponse = await ApiService.AutoLogin(this.access_token)
            // console.log(loginResponse)
            this.$store.commit('toast/Notify', {
                type: loginResponse['status'],
                message: loginResponse['message']
            })
            if (loginResponse['status'] != 'success') {} else {
                this.$store.commit('auth/SAVE_ACCESS_TOKEN', loginResponse['user']['remember_token'])
                let currentUser = this.userDataToCommitToStore(loginResponse['user'])
                this.$store.commit('user/SAVE_CURRENT_USER', currentUser)
                this.$store.commit('user/SAVE_CURRENT_USER_SUBSCRIPTION', loginResponse['subscription'])
                // this.$router.push("/dashboard/blogs");
                if (!loginResponse['subscription']) {
                    location.href = '/dashboard/complete-registration'
                } else {
                    location.href = '/dashboard'
                }
            }
            this.toggleProgress('hide')
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
