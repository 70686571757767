import moment from "moment";
import { ApiService } from "../services/api.service";

export default {
    data() {
        return {
            delay: 700,
            clicks: 0,
            timer: null
        };
    },
    methods: {
        defaultImageUrl: function() {
            return '/assets/img/def-img.png'
        },
        defaultPostUrl: function() {
            return '/assets/img/def-img.png'
        },
        timeAgo: function(d) {

            if (!d) {
                return ''
            }
            let date = Date.parse(d)

            var seconds = Math.floor((new Date() - date) / 1000);

            var interval = seconds / 31536000;
            let floorVal = ''

            if (interval > 1) {
                return Math.floor(interval) + " y";
            }
            interval = seconds / 2592000;
            if (interval > 1) {
                return Math.floor(interval) + " m";
            }
            interval = seconds / 86400;
            if (interval > 1) {
                return Math.floor(interval) + " d";
            }
            interval = seconds / 3600;
            if (interval > 1) {
                floorVal = Math.floor(interval)
                if (floorVal == 1) {
                    floorVal = floorVal + ' Hr'
                } else {
                    floorVal = floorVal + " Hrs"
                }
                return floorVal;
            }
            interval = seconds / 60;
            if (interval > 1) {
                floorVal = Math.floor(interval)
                if (floorVal == 1) {
                    floorVal = floorVal + ' Min'
                } else {
                    floorVal = floorVal + " Mins"
                }
                return floorVal;
            }
            floorVal = Math.floor(seconds)
            if (floorVal == 1) {
                floorVal = floorVal + ' Sec'
            } else {
                floorVal = floorVal + " Secs"
            }
            return floorVal;
        },
        feedTimeManager(a) {
            let b = this.timeAgo(a)
            if (b.search('d') > -1 || b.search('m') > -1 || b.search('y') > -1) {
                b = this.formatDateTimeNice(a)
            } else {
                b = b + ' ago'
            }
            return b
        },
        unixToUtc: function(a) {
            let b = a
            if (b) {
                b = moment.unix(b / 1000).format("DD-MM-YYYY HH:mm:ss");
            }
            return b
        },
        daysLater: function(d) {

            if (!d) {
                return ''
            }
            let todaysDate = new Date()
            let date = new Date(d)

            let todaysDateDay = todaysDate.getDate()
            let dobDateDay = date.getDate()

            let daysLeft = dobDateDay - todaysDateDay

            return daysLeft
        },
        ageCalculator: function(d) {

            if (!d) {
                return ''
            }
            let date = Date.parse(d)

            var seconds = Math.floor((new Date() - date) / 1000);

            var interval = seconds / 31536000;

            return Math.floor(interval);
        },
        stripTags: function(a) {
            let b = a.replace(/(<([^>]+)>)/gi, "");
            return b
        },
        blogMinify: function(a, length) {
            let b = a
            if (a.length > length) {
                b = a.substr(0, length)
            }
            return b
        },
        textMinify: function(a, length) {
            let b = a
            if (a && a.length > length) {
                b = a.substr(0, length) + '...'
            }
            return b
        },
        currencySymbol: function(a) {
            let b = a
            switch (a.toUpperCase()) {
                case 'USD':
                    b = '$'
                    break;

                case 'NGN':
                    b = '₦'
                    break;

                case 'EUR':
                    b = 'E'
                    break;

                case 'SHILLING':
                    b = 'KSh'
                    break;

                case 'CEDI':
                    b = '₵'
                    break;

                default:
                    break;
            }

            return b
        },
        formatDate: function(a) {
            if (a) {
                return moment(String(a)).format('Do, MMMM YYYY')
            }
        },
        formatDateTimeNice: function(a) {
            if (a) {
                return moment(String(a)).format('Do, MMMM YYYY HH:mm:ss A')
            }
        },
        formatDateTimeNiceShort: function(a) {
            if (a) {
                return moment(String(a)).format('D-M-YYYY HH:mm:ss')
            }
        },
        formatDateTime: function(a) {
            if (a) {
                return moment(String(a)).format('dddd, d MMMM YYYY')
            }
        },
        formatAmount: function(a) {
            if (a) {
                a = Number(a).toLocaleString('en-US')
            }
            return a
        },
        formatDateForum: function(a) {
            if (a) {
                return moment(String(a)).format('MMM Do, YYYY')
            }
        },
        formatDateTimeToFuture: function(date, toAdd, numberToAdd) {
            if (date) {
                return moment(date).add(numberToAdd, toAdd).format('dddd, Do MMMM YYYY hh:mm:ss');
            }
        },
        shortenNumber(a) {
            let b = a
            if (a) {
                if (Math.abs(a) > 999) {
                    b = Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k'
                } else if (Math.abs(a) > 999999) {
                    b = Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'm'
                } else if (Math.abs(a) > 999999999) {
                    b = Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'b'
                }
            }
            return b
        },
        focusTextarea(a) {
            console.log('aaa', a)
            $('html, body').animate({
                    scrollTop: $(a + ' textarea').offset().top - 300
                },
                1000,
                function() {
                    $(a + ' textarea').focus();
                });
        },
        scrollToContent(a) {
            try {
                $('html, body').animate({
                        scrollTop: $(a).offset().top - 300
                    },
                    1000,
                    function() {
                        $(a).focus();
                    });

            } catch (error) {
                console.log('E scrollToContent: ', error, a)
            }
        },
        openLink(link) {
            location.href = link
        },
        openLinkInNewTab(link) {
            var win = window.open(link, '_blank');
            if (win) {
                //Browser has allowed it to be opened
                win.focus();
            } else {
                //Browser has blocked it
                this.$store.commit("toast/Notify", {
                    type: 'warning',
                    message: 'Please allow popups for this website',
                });
            }
        },
        randomizeItems(a) {
            let b = a
            for (let i = b.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                const temp = b[i];
                b[i] = b[j];
                b[j] = temp;
            }

            return b
        },
        copyTextToClipboard(element) {
            var $temp = $("<input hidden>");
            $("body").append($temp);
            $temp.val($(element).text()).select();
            document.execCommand("copy");
            $temp.remove();

            this.$store.commit("toast/Notify", {
                type: 'success',
                message: 'Text copied',
            });
        },
        copyToClipboard(element) {
            var $temp = $("<input>");
            $("body").append($temp);
            $temp.val($(element).val()).select();
            document.execCommand("copy");
            $temp.remove();

            this.$store.commit("toast/Notify", {
                type: 'success',
                message: 'Text copied',
            });
        },
        noty(status, message) {
            return this.$store.commit("toast/Notify", {
                type: status,
                message: message,
            });
        },
        togglePopover(id) {
            $("#" + id).toggle()
        },
        toggleElements(a, b) {
            $(a).toggle()
        },
        toggleItem(id, c) {
            if (c) {
                if ($("#" + id).css('display') == 'block') {
                    $("." + c).hide()
                } else {
                    $("#" + id).toggle()
                }
            } else {
                $("#" + id).toggle()
            }
        },
        toggleTooltip(id) {
            // console.log('ev', id)
            UIkit.tooltip(id).show();
        },
        replaceAll(a, b, c) {
            return a.split(b).join(c);
        },
        checkTokenValidity(payload) {
            if (payload.message.search("invalid token") > -1) {
                localStorage.removeItem('access_token')
                localStorage.removeItem('currentUser')
            }
        },
        userDataToCommitToStore(user) {
            let a = user
            if (user) {
                a = {
                    id: user['id'],
                    role: '',
                    name: user['name'],
                    email: user['email'],
                    profile_photo: user['profile_photo'],
                    banner_photo: user['banner_photo'],
                    verified: user['verified'] || 'false',
                    username: user['username'],
                    phone: user['phone'],
                    type: user['type']
                }
            }
            return a
        },
        toggleProgress(a) {
            if (a == 'show') {
                $("#preloader").show()
            } else if (a == 'hide') {
                $("#preloader").hide()
            } else {
                $("#preloader").toggle()
            }
        },
        copyright() {
            const d = new Date();
            let year = d.getFullYear();
            let b = '2019 - ' + year
            return b
        },
        userBrowser() {
            let browser = ''
            if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
                browser = ('Opera');
            } else if (navigator.userAgent.indexOf("Edg") != -1) {
                browser = ('Edge');
            } else if (navigator.userAgent.indexOf("Chrome") != -1) {
                browser = ('Chrome');
            } else if (navigator.userAgent.indexOf("Safari") != -1) {
                browser = ('Safari');
            } else if (navigator.userAgent.indexOf("Firefox") != -1) {
                browser = ('Firefox');
            } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) //IF IE > 10
            {
                browser = ('IE');
            } else {
                browser = ('unknown');
            }
            return browser
        }
    },
}