<template>
  <section class="body-sign">
      <div class="center-sign">
          <a href="/" class="logo pull-left">
              <img src="/images/logo.png" height="54" alt="ViralTrend &dash; Go Viral" />
          </a>
  
          <div class="panel panel-sign">
              <div class="panel-title-sign mt-xl text-right">
                  <h2 class="title text-uppercase text-bold m-none"><i class="fa fa-user mr-xs"></i> Activate Account</h2>
              </div>
              <div class="panel-body">
                <div class="text-center">
                    <i class="fa fa-spin fa-spinner fa-2x"></i>
                </div>
                <div class="text-center">
                    <h5>{{ apiStatus }}</h5>
                    <p>{{ apiMessage }}</p>
                </div>
              </div>
  
          </div>
  
          <p class="text-center text-muted mt-md mb-md">
              &copy; Copyright {{ copyright() }}, 
              <span>
                  <a href="https://viraltrend.org">ViralTrend</a>
              </span>
          </p>
      </div>
  </section>
</template>

<script>
import { ApiService } from '@/services/api.service'

export default {
  name: 'AccountActivation',
  data () {
    return {
      code: '',
      expire_at: '',
      apiStatus: '',
      apiMessage: '',
      error: ''
    }
  },
  components: {
  },
  mounted() {
    //   console.log(this.$route.params)
      let params = this.$route.params
      this.code = params['code']
      this.expire_at = params['expire_at']

      this.verifyAccount()
  },
  methods: {
    async verifyAccount () {
        if(!this.code){
            return this.$store.commit('toast/Notify', { type: 'warning', message: 'code is required' })
        }
        if(!this.expire_at){
            return this.$store.commit('toast/Notify', { type: 'warning', message: 'expire_at is required' })
        }
        this.toggleProgress('show')
        let apiResponse = await ApiService.ActivateAccount(this.code,this.expire_at)
        // console.log(apiResponse)
        this.$store.commit('toast/Notify', { type: apiResponse['status'], message: apiResponse['message'] })
        if(apiResponse['status'] == 'success'){
            this.apiStatus = 'success'
            setTimeout(() => {
                location.href = '/login'
            }, 1300);
        }else{
            this.apiStatus = 'error'
            this.apiMessage = apiResponse['message']
        }
        this.toggleProgress('hide')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
