// layouts
import DashboardLayout from '@/layout/DashboardLayout'

// single pages
import Page404 from '@/pages/home/Page404'

import AccountActivation from '@/pages/auth/AccountActivation'
import AutoLogin from '@/pages/auth/AutoLogin'
import LoginPage from '@/pages/auth/LoginPage'
import RegisterPage from '@/pages/auth/RegisterPage'
import RequestAccountActivation from '@/pages/auth/RequestAccountActivation'
import ResetPassword from '@/pages/auth/ResetPassword'

import CurrentTask from '@/pages/dashboard/CurrentTask'
import Dashboard from '@/pages/dashboard/Dashboard'
import Profile from '@/pages/dashboard/Profile'
import Referrals from '@/pages/dashboard/Referrals'
import Tasks from '@/pages/dashboard/Tasks'
import Wallet from '@/pages/dashboard/Wallet'
import CompleteRegistration from '@/pages/dashboard/CompleteRegistration'

export const routes = [{
        path: '/',
        name: 'login',
        meta: {
            layout: 'welcome',
            requiresAuth: false
        },
        component: LoginPage
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            layout: 'welcome',
            requiresAuth: false
        },
        component: LoginPage
    },
    {
        path: '/register',
        name: 'register',
        meta: {
            layout: 'welcome',
            requiresAuth: false
        },
        component: RegisterPage
    },
    {
        path: '/register/:referral_id',
        name: 'register-by-referral',
        meta: {
            layout: 'welcome',
            requiresAuth: false
        },
        component: RegisterPage
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        meta: {
            layout: 'welcome',
            requiresAuth: false
        },
        component: ResetPassword
    },
    {
        path: '/account-activation',
        name: 'account-activation',
        meta: {
            layout: 'welcome',
            requiresAuth: false
        },
        component: RequestAccountActivation
    },
    {
        path: '/auth/activate-account/:code/:expire_at',
        name: 'activate-account',
        meta: {
            layout: 'welcome',
            requiresAuth: false
        },
        component: AccountActivation
    },
    {
        path: '/auto/:key',
        name: 'auto',
        meta: {
            layout: 'welcome',
            requiresAuth: false
        },
        component: AutoLogin
    },
    {
        path: '/auto-login/:key',
        name: 'auto-login',
        meta: {
            layout: 'welcome',
            requiresAuth: false
        },
        component: AutoLogin
    },
    {
        path: '/auto_login/:key',
        name: 'auto_login',
        meta: {
            layout: 'welcome',
            requiresAuth: false
        },
        component: AutoLogin
    },
    {
        path: '/dashboard',
        name: 'dashboardL',
        meta: {
            layout: 'default'
        },
        component: DashboardLayout,
        children: [{
                path: '',
                name: 'dashboard',
                meta: {
                    layout: 'default',
                    requiresAuth: true
                },
                component: Dashboard
            },
            {
                path: 'wallet',
                name: 'wallet',
                meta: {
                    layout: 'default',
                    requiresAuth: true
                },
                component: Wallet
            },
            {
                path: 'complete-registration',
                name: 'complete-registration',
                meta: {
                    layout: 'default',
                    requiresAuth: true
                },
                component: CompleteRegistration
            },
            {
                path: 'current-task',
                name: 'current-task',
                meta: {
                    layout: 'default',
                    requiresAuth: true
                },
                component: CurrentTask
            },
            {
                path: 'referrals',
                name: 'referrals',
                meta: {
                    layout: 'default',
                    requiresAuth: true
                },
                component: Referrals
            },
            {
                path: 'tasks',
                name: 'tasks',
                meta: {
                    layout: 'default',
                    requiresAuth: true
                },
                component: Tasks
            },
            {
                path: 'profile',
                name: 'profile',
                meta: {
                    layout: 'default',
                    requiresAuth: true
                },
                component: Profile,
                props: true
            }
        ]
    },
    {
        path: '*',
        name: '404',
        meta: {
            layout: 'welcome',
            requiresAuth: false
        },
        component: Page404
    }
]