/**
 * toast notification module
 */

import Vue from "vue";

export default {
    namespaced: true,
    state: {
        toastsList: []
    },

    mutations: {
        Notify(state, payload) {
            console.log('payload', payload)
            console.log('payload', payload.type)
            if (payload.type == 'success') {
                Vue.$toast.success(payload.message);
            } else if (payload.type == 'warning') {
                Vue.$toast.warning(payload.message);
            } else {
                Vue.$toast.error(payload.message);
            }
            if (payload.message.search("invalid token") > -1) {
                console.log("Redirect")
                localStorage.removeItem('access_token')
                localStorage.removeItem('currentUser')
                setTimeout(() => {
                    location.href = '/login'
                }, 1000);
            }
        },
        NEW(state, payload) {
            const toast = {
                id: new Date().getTime(),
                message: typeof payload === 'string' ? payload : payload.message,
                type: payload.type || 'default',
                duration: payload.duration || 5000
            }
            if (payload.type === 'error') {
                // toast.duration = 0
                console.error(payload.message)
            }
            state.toastsList.push({...toast })
        },
        REMOVE(state, payload) {
            state.toastsList = state.toastsList.filter(({ id }) => id !== payload.id)
        },
        CLEAR_LIST(state) {
            state.toastsList = []
        }
    }
}