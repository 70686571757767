<template>
  
    <transition name="fade">
        <router-view></router-view>
    </transition>

</template>

<script>

export default {
  name: 'AuthLayout',
  data(){
    return {}
  },
  components: {
  },
  mounted: function () {
    // disable dark mode 
    console.log('====-----------',$("html").hasClass('dark'))
    this.toggleProgress('hide')
  },
}
</script>
