import Vue from 'vue'

import AppLayout from './layout/index.vue'
import AuthLayout from './layout/AuthLayout.vue'
import router from './router'
import store from './store'
import VueHead from 'vue-head'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import vueCountryRegionSelect from 'vue-country-region-select'
import FileUpload from 'v-file-upload'

import utilMixin from './mixins/utils.js'

import axios from 'axios'
import Ads from 'vue-google-adsense'



Vue.config.productionTip = false

let host = window.location.hostname;
Vue.prototype.$APP_NAME = 'Vitisocial'
Vue.prototype.$axios = axios


const commitWindowWidth = () => store.commit('dom/SET_WINDOW_WIDTH', window.innerWidth)

Vue.use(VueHead)
const options = {
    timeout: 5000
};


Vue.use(Toast, options);
Vue.use(vueCountryRegionSelect)
Vue.use(FileUpload)

Vue.use(require('vue-script2'))

Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)



Vue.mixin(utilMixin)

new Vue({
    name: 'RootTemplate',
    router,
    store,
    mounted() {
        commitWindowWidth()
        window.addEventListener('resize', commitWindowWidth)
    },

    beforeDestroy() {
        window.removeEventListener('resize', commitWindowWidth)
    },
    render: h => h(AppLayout)
}).$mount('#app')