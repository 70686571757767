<template>
<div class="upload-single component">
    <div v-if="loading == false">
        <div v-if="type == 'image'">
            <input class="upload-input" type="file" @change="onFileChange" accept="image/*" ref="file">
        </div>
        <div v-else-if="type == 'audio'">
            <input class="upload-input" type="file" @change="onFileChange" accept="audio/*" ref="file">
        </div>
        <div v-else-if="type == 'video'">
            <input class="upload-input" type="file" @change="onFileChange" accept="video/*" ref="file">
        </div>
        <div v-else>
            <input class="upload-input" type="file" @change="onFileChange" ref="file">
        </div>
        <slot></slot>
    </div>
    <div v-else>
        <i class="fa fa-spin fa-spinner fa-2x"></i>
    </div>
</div>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";
import {
    FileUploadService
} from 'v-file-upload'
import FileUpload from 'v-file-upload'
export default {
    name: 'SingleFileUpload',
    props: {
        type: String,
    },
    data() {
        return {
            previewImageData: '',
            imageMaxSize: "10072864",
            audioMaxSize: "500000",
            videoMaxSize: "10072864",
            imageAccept: ".png,.jpg,.jpeg,.gif",
            audioAccept: ".mp3",
            videoAccept: ".mp4,.avi,.3gp",
            file: "",
            url: '',
            maxSize: '',
            loading: false,
            headers: {}
        }
    },
    components: {
        FileUpload
    },
    mounted() {
        this.url = this.$store.getters['host/apiUrl'].replace('/api/v2/promoters', '/files/upload')
    },
    methods: {
        saveFile(file) {
            this.toggleProgress('show')
            this.loading = true
            console.log('file', this.url)
            let fileUpload = new FileUploadService(this.url, this.headers, this.onProgress)
            fileUpload
                .upload(file, {})
                .then(a => {
                    let e = a.target.response
                    // Handle success
                    // console.log('upload then',e)
                    this.$emit('uploadSuccess', e)
                    this.loading = false
                    this.toggleProgress('hide')
                })
                .catch(a => {
                    let e = a.target.response
                    // Handle error
                    // console.log('upload error',e)
                    this.$store.commit("toast/Notify", {
                        type: e["status"],
                        message: e["message"],
                    });
                    this.loading = false
                    this.toggleProgress('hide')
                })
        },
        onProgress(event) {
            // Handdle the progress
            console.log(event)
        },
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                this.$store.commit("toast/Notify", {
                    type: 'warning',
                    message: 'File not found',
                });
                return
            }
            this.toggleProgress('show')
            let filePassed = 'true'
            let theFileSize = ''
            let maxFileSizeUnits = ''
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                if(this.type == 'image'){
                    this.maxSize = this.imageMaxSize
                }else if(this.type == 'audio'){
                    this.maxSize = this.audioMaxSize
                }else if(this.type == 'video'){
                    this.maxSize = this.videoMaxSize
                }else{
                    this.maxSize = this.audioMaxSize
                }

                if (file.size > this.maxSize) {
                    filePassed = 'false'
                    theFileSize = parseInt(file.size)/1000
                    if(theFileSize > 1000){
                        theFileSize = parseInt(theFileSize)/1000
                    }else{
                        theFileSize = parseInt(theFileSize)+'kb'
                    }
                    if(theFileSize > 1000){
                        theFileSize = parseInt(theFileSize)/1000
                    }else{
                        theFileSize = parseInt(theFileSize)+'mb'
                    }
                    maxFileSizeUnits = parseInt(this.maxSize)/1000
                    if(maxFileSizeUnits > 1000){
                        maxFileSizeUnits = parseInt(maxFileSizeUnits)/1000
                    }else{
                        maxFileSizeUnits = parseInt(maxFileSizeUnits)+'kb'
                    }
                    if(maxFileSizeUnits > 1000){
                        maxFileSizeUnits = parseInt(maxFileSizeUnits)/1000
                    }else{
                        maxFileSizeUnits = parseInt(maxFileSizeUnits)+'mb'
                    }
                    this.noty('error', 'Max file size of '+maxFileSizeUnits+' exceeded: '+theFileSize)
                    break
                }
            }
            this.toggleProgress('hide')
            if(filePassed == 'true'){
                this.file = files
                this.saveFile(files[0])
            }
        },
        onFileChangeFileUpload(file) {
            // Handle files like:
            this.file = file
        },
    }
}
</script>

<style scoped>
.upload-single {
    .preview {
        width: 300px;
    }
}

.upload-input {
    height: 50px;
    width: 50px;
    padding: 0;
    opacity: 0;
    cursor: pointer;
    position: absolute;
    z-index: 99999;
}

.file-upload .file-upload-progress {
    display: none;
}
</style>
