export default {
    currentUser: {
        id: '',
        role: '',
        name: '',
        email: '',
        profile_photo: '',
        username: '',
        type: ''
    }
}