import auth from './auth'
import user from './user'
import dom from './dom'
import host from './host'
import toast from './toast'

export default {
    auth,
    user,
    dom,
    host,
    toast
}