import $store from '../store'
import utils from '../mixins/utils'

import { API_URL } from '../.env'
import axios from 'axios'

export class ApiService {
    static async ExtraInfo() {
        let data = {}

        return await apiConnect('/extra_info', data)
    }
    static async FindUserEmail(email) {
        let data = {
            email
        }

        return await apiConnect('/find_user_email', data)
    }
    static async AutoLogin(access_token) {
        let data = {
            access_token
        }

        return await apiConnect('/autologin', data)
    }
    static async LoginUser(email, password) {
        let data = {
            'email': email,
            'password': password
        }

        return await apiConnect('/login', data)
    }
    static async RegisterUser(email, password, repeat_password, first_name, last_name, username, gender, phone, date_of_birth, country, state, referral) {
        let data = {
            'email': email,
            'password': password,
            'repeat_password': repeat_password,
            'first_name': first_name,
            'last_name': last_name,
            'username': username,
            'gender': gender,
            'phone': phone,
            'date_of_birth': date_of_birth,
            'referral': referral,
            'country': country,
            'state': state
        }

        return await apiConnect('/register', data)
    }
    static async ActivateNewAccount(code, email) {
        let data = {
            'code': code,
            'email': email
        }

        return await apiConnect('/activate-account', data)
    }
    static async RequestAccountActivation(email) {
        let data = {
            'email': email
        }

        return await apiConnect('/request_account_activation', data)
    }
    static async ActivateAccount(code, expire_at) {
        let data = {
            'code': code,
            'expire_at': expire_at
        }

        return await apiConnect('/activate-account', data)
    }
    static async ResetPassword(email) {
        let data = {
            'email': email,
        }

        return await apiConnect('/forgot_password/verify_email', data)
    }
    static async ChangePassword(email, code, password, repeat_password) {
        let data = {
            'email': email,
            'code': code,
            'password': password,
            'repeat_password': repeat_password
        }

        return await apiConnect('/forgot_password/change_password', data)
    }
    static async GetWallet() {
        let data = {}

        return await apiConnect('/dashboard/user/wallet', data)
    }
    static async GetBankAccount() {
        let data = {}

        return await apiConnect('/dashboard/user/get-account', data)
    }
    static async AddBankAccount(account_name, account_no, bank, account_type) {
        let data = { account_name, account_no, bank, account_type }

        return await apiConnect('/dashboard/user/add-account', data)
    }
    static async GetWithdrawals(page, per_page) {
        let data = { page, per_page }

        return await apiConnect('/dashboard/user/get-withdrawals', data)
    }
    static async Withdraw(amount) {
        let data = { amount }

        return await apiConnect('/dashboard/user/withdraw', data)
    }
    static async GetUserTasks(page, per_page) {
        let data = { page, per_page }

        return await apiConnect('/dashboard/user/tasks', data)
    }
    static async GetSocialAccounts() {
        let data = {}

        return await apiConnect('/dashboard/user/get-social', data)
    }
    static async AddSocialAccount(facebook, twitter, youtube, instagram, tiktok, threads) {
        let data = {
            facebook,
            twitter,
            youtube,
            instagram,
            tiktok,
            threads
        }

        return await apiConnect('/dashboard/user/add-social', data)
    }
    static async ProfileCompletion() {
        let data = {}

        return await apiConnect('/dashboard/user/profile-completion', data)
    }
    static async ChangePasswordFromProfile(current_password, new_password, confirm_new_password) {
        let data = { current_password, new_password, confirm_new_password }

        return await apiConnect('/dashboard/user/change-password-from-profile', data)
    }
    static async GetReferrals(page, per_page) {
        let data = { page, per_page }

        return await apiConnect('/dashboard/user/referrals', data)
    }
    static async GetCommissionTransactions(page, per_page) {
        let data = { page, per_page }

        return await apiConnect('/dashboard/user/commissions', data)
    }
    static async GetTasks(page, per_page) {
        let data = { page, per_page }

        return await apiConnect('/dashboard/tasks', data)
    }
    static async AcceptTask( id ) {
        let data = { id }

        return await apiConnect('/dashboard/accept-task', data)
    }
    static async RejectUserTask( id, reason ) {
        let data = { id, reason }

        return await apiConnect('/dashboard/reject-task', data)
    }
    static async PerformTask(id) {
        let data = {id}

        return await apiConnect('/dashboard/perform-task', data)
    }
    static async PerformUserTask(id, email, social, proof_of_task_url) {
        let data = {id, email, social, proof_of_task_url}

        return await apiConnect('/dashboard/perform-user-task', data)
    }
    static async GetCurrentTask() {
        let data = {}

        return await apiConnect('/dashboard/current-task', data)
    }
    static async UserSubscribe(amount, plan, transaction_id) {
        let data = {amount, plan, transaction_id}

        return await apiConnect('/dashboard/user/subscribe', data)
    }
    static async GetPlans() {
        let data = {}

        return await apiConnect('/dashboard/plans', data)
    }
    static async GetDashboardExtra() {
        let data = {}

        return await apiConnect('/dashboard/get-extra', data)
    }
    static async ViewPostAd(id) {
        let data = {id}

        return await apiConnect('/dashboard/view-post-ad', data)
    }
    static async ClickPostAd(id) {
        let data = {id}

        return await apiConnect('/dashboard/click-post-ad', data)
    }
    static async GetUserNoty() {
        let data = {}

        return await apiConnect('/dashboard/noty/get', data)
    }
    static async ReadUserNoty(id) {
        let data = {id}

        return await apiConnect('/dashboard/noty/mark', data)
    }

    // ------------
    // to be removed
    // ----------------------------

    // static async SavePhoto(reach, description, url) {
    //     let data = {
    //         'reach': reach,
    //         'description': description,
    //         'url': url
    //     }

    //     return await apiConnect('/dashboard/save_photo', data)
    // }
    // static async GetPhotos(page, id) {
    //     let data = {
    //         'id': id,
    //         'page': page
    //     }

    //     return await apiConnect('/dashboard/get_photos', data)
    // }
    // static async LikePhoto(id) {
    //     let data = {
    //         'id': id
    //     }

    //     return await apiConnect('/dashboard/like_photo', data)
    // }
    // static async UnlikePhoto(id) {
    //     let data = {
    //         'id': id
    //     }

    //     return await apiConnect('/dashboard/unlike_photo', data)
    // }
    // static async SaveVideo(title, description, reach, url, age_restrict, keywords) {
    //     let data = {
    //         'reach': reach,
    //         'title': title,
    //         'description': description,
    //         'age_restrict': age_restrict,
    //         'url': url,
    //         'keywords': keywords
    //     }

    //     return await apiConnect('/dashboard/save_video', data)
    // }
    // static async GetVideos(page, section) {
    //     let data = {
    //         'page': page,
    //         'section': section
    //     }

    //     return await apiConnect('/dashboard/get_videos', data)
    // }
    // static async GetUserVideos(page, id, section) {
    //     let data = {
    //         'id': id,
    //         'page': page,
    //         'section': section
    //     }

    //     return await apiConnect('/dashboard/get_user_videos', data)
    // }
    // static async LikeVideo(id) {
    //     let data = {
    //         'id': id
    //     }

    //     return await apiConnect('/dashboard/like_video', data)
    // }
    // static async UnlikeVideo(id) {
    //     let data = {
    //         'id': id
    //     }

    //     return await apiConnect('/dashboard/unlike_video', data)
    // }
    // static async DislikeVideo(id) {
    //     let data = {
    //         'id': id
    //     }

    //     return await apiConnect('/dashboard/dislike_video', data)
    // }
    // static async UndislikeVideo(id) {
    //     let data = {
    //         'id': id
    //     }

    //     return await apiConnect('/dashboard/undislike_video', data)
    // }
    // static async AddVideoComment(id, comment) {
    //     let data = {
    //         'id': id,
    //         'comment': comment,
    //     }

    //     return await apiConnect('/dashboard/comment_video', data)
    // }
    // static async DeleteVideoComment(id, comment) {
    //     let data = {
    //         'id': id,
    //         'comment': comment,
    //     }

    //     return await apiConnect('/dashboard/delete_comment_video', data)
    // }
    // static async FavouriteVideo(id) {
    //     let data = {
    //         'id': id
    //     }

    //     return await apiConnect('/dashboard/favourite_video', data)
    // }
    // static async UnfavouriteVideo(id) {
    //     let data = {
    //         'id': id
    //     }

    //     return await apiConnect('/dashboard/favourite_video', data)
    // };
    //-------------------------
}

async function apiConnect(url, data) {
    let accessToken = $store.getters['auth/accessToken']
        // console.log(accessToken, 'accessToken')
    if (accessToken) {
        data['access_token'] = accessToken
    }
    // console.log(data, 'data')
    return await $.ajax({
        url: $store.getters['host/apiUrl'] + '' + url,
        method: 'POST',
        data: data,
        success: function(response) {
            if (response.message) {
                checkTokenValidity(response)
            }
            return response
        },
        error: function(response) {
            console.log(response)
            return response
        }
    })

}

function checkTokenValidity(payload) {
    if (payload.message.search("invalid token") > -1) {
        localStorage.removeItem('access_token')
        localStorage.removeItem('currentUser')

        location.href = '/login'
    }
}