<template>
    
    <section class="body-sign">
        <div class="center-sign">
            <a href="/" class="logo pull-left">
                <img src="/images/logo.png" height="54" alt="ViralTrend &dash; Go Viral" />
            </a>

            <div class="panel panel-sign">
                <div class="panel-title-sign mt-xl text-right">
                    <h2 class="title text-uppercase text-bold m-none"><i class="fa fa-user mr-xs"></i> 404</h2>
                </div>
                <div class="panel-body">
                    <div id="" >
                        <div class="alert alert-warning viral-form-alert text-center" id="noVerification">
                            <strong> 
                                Page not found
                            </strong> 
                        </div>

                        <p class="text-center" >
                            <a :href="'/dashboard'">Dashboard!</a>
                        </p>
                        <p class="text-center" >
                            <a :href="'/login'">Login!</a>
                        </p>
                        <p class="text-center" >
                            <a :href="'/register'">Register!</a>
                        </p>
                                
                    </div>
                </div>


            </div>

            <p class="text-center text-muted mt-md mb-md" >
                &copy; Copyright {{ copyright() }}, 
                <span>
                    <a href="https://viraltrend.org">ViralTrend</a>
                </span>
            </p>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Page404',
    components: {
    },
    data() {
        return {
            currentYear: new Date().getFullYear(),
            faqData: [{
                'question': 'How do I register either as an advertiser or a promoter?',
                'answer': ""
            }]
        }
    },

    methods: {}
}
</script>

<style>
</style>
<style lang="scss" scoped>
</style>
