<template>
<transition>
    <section id="app" class="body">
        <!---->
        <div v-if="notification" class="noty_holder">
            <p class="text-left" style="margin:0; padding:0">
                <i class="fa fa-close" @click="notification = ''"></i>
            </p>
            <p>{{ notification.message }}</p>
        </div>
        <header class="header">
            <div class="logo-container">
                <a href="/" class="logo">
                    <img src="/images/logo.png" height="45" alt="ViralTrend">
                </a>
                <div data-toggle-class="sidebar-left-opened" data-target="html" data-fire-event="sidebar-left-opened" class="visible-xs toggle-sidebar-left">
                    <i aria-label="Toggle sidebar" class="fa fa-bars " style="font-size: 16px;"></i>
                </div>
            </div>
            <div class="header-right">
                <span class="" style="padding-left: 20px;">
                    <a href="https://vitisocial.com" class="hidden-xsold visible" style="color: rgb(0, 0, 0); padding-left: 20px;">Vitisocial</a> 
                </span>
                <span class="separator"></span>
                <div class="userbox wallet-balance">
                    <div><span>Account :&nbsp;{{ currencySymbol(currency) }}<span>{{ formatAmount(wallet) }}</span></span></div>
                </div> 
                <span class="separator"></span>
                <div id="userbox" class="userbox">
                    <a href="#" data-toggle="dropdown">
                        <div data-lock-name=" " data-lock-email="" class="profile-info">
                            <span class="name text-capitalize">{{ currentUser.name }}</span> 
                            <span class="role text-capitalize">Promoter ({{ subscription }})</span>
                        </div> 
                        <i class="fa custom-caret"></i>
                    </a>
                    <div class="dropdown-menu">
                        <ul class="list-unstyled">
                            <li class="divider"></li>
                            <li><a role="menuitem" tabindex="-1" href="/dashboard/profile"><i class="fa fa-user"></i> My Profile</a></li>
                            <li @click="logout"><a role="menuitem" tabindex="-1" href="Javascript:;"><i class="fa fa-power-off"></i> Logout</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
        <div class="inner-wrapper">
            <aside v-if="hideSidebar == false" id="sidebar-left" class="sidebar-left viral-sidebar-left">
                <div class="nano">
                    <div class="nano-content">
                        <nav id="menu" role="navigation" class="nav-main">
                            <ul class="nav nav-main">
                                <li :class="dashboardActive">
                                    <a href="/dashboard">
                                        <i aria-hidden="true" class="fa fa-home"></i> 
                                        <span>Dashboard</span>
                                    </a>
                                </li>
                                <li :class="taskActive">
                                    <a href="/dashboard/tasks">
                                        <i aria-hidden="true" class="fa fa-tasks"></i> 
                                        <span>Tasks</span>
                                    </a>
                                </li>
                                <li :class="walletActive">
                                    <a href="/dashboard/wallet">
                                        <i aria-hidden="true" class="fa fa-money"></i> 
                                        <span>Wallet</span>
                                    </a>
                                </li>
                                <li :class="refActive">
                                    <a href="/dashboard/referrals">
                                        <i aria-hidden="true" class="fa fa-group"></i> 
                                        <span>Referrals</span>
                                    </a>
                                </li>
                                <li :class="profileActive">
                                    <a href="/dashboard/profile">
                                        <i aria-hidden="true" class="fa  fa-user"></i> 
                                        <span>Profile</span>
                                    </a>
                                </li>
                                <li style="position: fixed; bottom: 10px;">
                                    <a @click="logout" href="Javascript:;">
                                        <i aria-hidden="true" class="fa fa-sign-out"></i> 
                                        <span>Log Out</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </aside>
            <router-view></router-view>
        </div>
    </section>
</transition>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
import {
    io
} from 'socket.io-client';

export default {
    name: "DashboardLayout",
    data() {
        return {
            msg: "",
            wallet: "",
            currency: "",
            currentUser: "",
            dashboardActive: "",
            taskActive: "",
            refActive: "",
            walletActive: "",
            profileActive: "",
            subscription: "",
            urlSegments: '',
            notification: '',
            loading: false,
            hideSidebar: false,
            socket: '',
            lockCheck: 0
        };
    },
    beforeUnmount() {
        this.disconnect()
    },
    created() {
        this.currentUser = this.$store.getters["user/currentUser"] || {};
    },
    beforeMount() {
        let urlSegments = location.pathname
        // console.log('=====', urlSegments)
        if(urlSegments == '/dashboard'){
            this.dashboardActive = 'nav-active'
        }else if(urlSegments == '/dashboard/wallet'){
            this.walletActive = 'nav-active'
        }else if(urlSegments == '/dashboard/tasks' || urlSegments == '/dashboard/current-task'){
            this.taskActive = 'nav-active'
        }else if(urlSegments == '/dashboard/referrals'){
            this.refActive = 'nav-active'
        }else if(urlSegments == '/dashboard/profile'){
            this.profileActive = 'nav-active'
        }
        let subscription = this.$store.getters["user/currentUserSubscription"];
        console.log('subscription',subscription, urlSegments, urlSegments == '/dashboard/complete-registration')
        if(urlSegments == '/dashboard/complete-registration'){
            this.hideSidebar = true
            if(subscription){
                // location.href = '/dashboard'
            }
        }else{
            this.hideSidebar = false
            if(!subscription){
                // location.href = '/dashboard/complete-registration'
            }
        }
        this.urlSegments = urlSegments
        
        this.toggleProgress('hide')
        this.getWallet()

    },
    mounted: function () {
        
        let urlSegments = this.urlSegments
        if(urlSegments == '/dashboard' || urlSegments == '/dashboard/wallet' || urlSegments == '/dashboard/tasks' || urlSegments == '/dashboard/current-task' || urlSegments == '/dashboard/referrals' || urlSegments == '/dashboard/profile' ){
            this.getUserNoty()
            this.setupSocketConnection()
        }
    },
    methods: {
        async getWallet() {
            this.toggleProgress('show')
            this.loading = true
            let apiResponse = await ApiService.GetWallet();
            // console.log('GetMe',apiResponse)
            if (apiResponse['status'] == 'success') {
                this.wallet = apiResponse['data']
                this.currency = apiResponse['currency']
                this.currentUser = apiResponse['user']
                this.$store.commit('user/SAVE_CURRENT_USER_SUBSCRIPTION', apiResponse['subscription'])
                if(apiResponse['subscription'] && apiResponse['subscription']['plan']){
                    this.subscription = apiResponse['subscription']['plan']
                }
            }
            if(this.urlSegments == '/dashboard/complete-registration'){
                if(this.subscription){
                    $('.content-body').remove()
                    location.href = '/dashboard'
                }
            }else{
                if(!this.subscription){
                    if(this.dashboardActive || this.walletActive || this.refActive || this.profileActive || this.taskActive){
                        $('.content-body').remove()
                        location.href = '/dashboard/complete-registration'
                    }
                }
            }
            this.loading = false
            this.toggleProgress('hide')
        },
        async getUserNoty() {
            let apiResponse = await ApiService.GetUserNoty();
            if (apiResponse['status'] == 'success') {
                this.notification = apiResponse['data']
                if(this.notification){
                    let vueThis = this
                    setTimeout(() => {
                        vueThis.readUserNoty(vueThis.notification['id'])
                    }, 3000);
                }
            }
        },
        async readUserNoty() {
            try {
                let apiResponse = await ApiService.ReadUserNoty(this.notification['id']);
                console.log('readUserNoty',apiResponse)
                if (apiResponse['status'] == 'success') {
                }
                
            } catch (error) {
                console.log('====', error)
            }
        },
        setupSocketConnection() {
            // this.lockCheck = this.lockCheck+1
            // let vueThis = this
            // this.socket = io(this.$store.getters['host/socketUrl']);
            // if (this.socket) {
            //     this.socket.on("connect", () => {
            //         console.log(this.socket.id); 
            //         this.$root.$emit("refresh-socket-id", this.socket)
            //     });

            //     this.socket.on("disconnect", () => {
            //         if(this.lockCheck < 5){
            //             setTimeout(() => {
            //                 vueThis.setupSocketConnection();
            //             }, 5000);
            //         }
            //     });
            // }
        },
        logout(){
            this.$store.commit("auth/CLEAR_ACCESS_TOKEN");
            this.$store.commit("user/CLEAR_CURRENT_USER");
            this.$store.commit("user/CLEAR_CURRENT_USER_SUBSCRIPTION");
            location.href = "/login";
        }
    },
};
</script>
<style>
.noty_holder {
    min-height: 60px;
    background-color: #1177f6;
    color: #ffffff;
    padding: 1.5rem;
    position: fixed;
    width: 100vw;
    z-index: 99999;
    text-align: center;
}
</style>
