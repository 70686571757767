<template>
<section role="main" class="content-body">
    <header class="page-header">
        <h2>Referrals</h2>
        <div class="right-wrapper pull-right">
            <ol class="breadcrumbs">
                <li><a href="/dashboard"><i class="fa fa-home"></i></a></li>
            </ol>
        </div>
    </header>
    <div class="panel">
        <div class="row">
            <div class="col-md-6">
                <h2 class="create-advert-intro">Referrals</h2>
                <h4 class="text-bold ">View Referrals and referral commission history.</h4>
            </div>
            <div class="col-md-6 viral-card panel-body">
                <h4 class="text-bold mb-xs mt-xs mr-xs btn btn-md btn-primary">Your Referral Link</h4> 
                <a class="pl-md pr-md pt-xs pb-xs text-center" style="font-size: 15px;">
                    {{ refLink }}
                </a>
            </div>
        </div>
        <div class="row mt-lg">
            <div class=" col-sm-12 col-md-12 col-lg-5 mt-sm mb-sm">
                <div class="panel panel-primary">
                    <header class="panel-heading">
                        <h2 class="panel-title text-bold">Referral Tree</h2>
                    </header>
                    <div class="viral-yellow viral-card">
                        <div id="treeBasic">
                            <ul>
                                <li v-for="ref in referrals">
                                    {{ ref.name }} <span v-if="ref.subscription && ref.subscription.plan">({{ ref.subscription.plan }})</span> {{ ref.country }} {{ formatDateTimeNiceShort(ref.created_at) }}
                                    <ul>
                                        <li v-for="userRefs in ref.referrals">
                                            <a class="mouse-pointer">
                                                {{ userRefs.name }} <span v-if="userRefs.subscription && userRefs.subscription.plan">({{ userRefs.subscription.plan }})</span> {{ userRefs.country }} {{ formatDateTimeNiceShort(userRefs.created_at) }}
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div class="text-center" v-if="refsLoading">
                            <i class="fa fa-spin fa-spinner fa-2x"></i>
                        </div>
                        <div class="text-center" v-if="!refsLoading && referrals.length == 0">
                            <h5>No referrals yet</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class=" col-sm-12 col-md-12 col-lg-7 mt-sm mb-sm">
                <div class="panel panel-primary">
                    <header class="panel-heading">
                        <h2 class="panel-title">Commision History</h2>
                    </header>
                    <div class="panel-body table-responsive">
                        <table id="grantDeposits" class="table mb-none">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Date</th>
                                    <th>Amount</th>
                                    <th>From</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="commission in commissions">
                                    <td class="active-advert-td">
                                        <span>
                                            {{ commission.id }}
                                        </span>
                                    </td>
                                    <td class="active-advert-td">
                                        {{ formatDateTimeNiceShort(commission.created_at) }}
                                    </td>
                                    <td class="active-advert-td">
                                        <span class="">
                                            <strong>
                                                {{ currencySymbol(currency) }}{{ formatAmount(commission.price.replace('-','')) }}
                                            </strong>
                                        </span>
                                    </td>
                                    <td class="active-advert-td">
                                        <span>
                                            {{ commission.description }}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="text-center" v-if="commissionsLoading">
                            <i class="fa fa-spin fa-spinner fa-2x"></i>
                        </div>
                        <div class="text-center" v-if="!commissionsLoading && commissions.length == 0">
                            <h5>No commissions yet</h5>
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'

export default {
    name: 'Wallet',
    components: {},
    data() {
        return {
            user: '',
            currentUser: {},
            thisUser: {},
            wallet: '',
            currency: '',
            commissions: '',
            referrals: '',
            refLink: '',
            walletLoading: false,
            commissionsLoading: false,
            refsLoading: false,
            toReceive: 0,
            page: 0,
            per_page: 25
        }
    },
    mounted: function () {
        this.thisUser = this.$store.getters["user/currentUser"];
        this.currentUser = this.thisUser
        this.id = this.thisUser['id']
        this.getWallet()
        this.getCommissionTransactions()
        this.getReferrals()
    },
    methods: {
        async getWallet() {
            this.toggleProgress('show')
            this.walletLoading = true
            let apiResponse = await ApiService.GetWallet();
            if (apiResponse['status'] == 'success') {
                this.wallet = apiResponse['data']
                this.currency = apiResponse['currency']
                this.currentUser = apiResponse['user']

                this.refLink = this.$store.getters["host/appUrl"]+'/register/'+this.currentUser['id']
            }
            this.walletLoading = false
            this.toggleProgress('hide')
        },
        async getCommissionTransactions() {
            this.toggleProgress('show')
            this.commissionsLoading = true
            let apiResponse = await ApiService.GetCommissionTransactions(this.page, this.per_page);
            if (apiResponse['status'] == 'success') {
                this.commissions = apiResponse['data']
            }
            this.commissionsLoading = false
            this.toggleProgress('hide')
        },
        async getReferrals() {
            this.toggleProgress('show')
            this.refsLoading = true
            let apiResponse = await ApiService.GetReferrals(this.page, this.per_page);
            if (apiResponse['status'] == 'success') {
                this.referrals = apiResponse['data']
            }
            this.refsLoading = false
            this.toggleProgress('hide')
        },
    },
}
</script>

<style>
.profilePage123 input {
    width: 157px !important;
    height: 32px !important;
}

.bannerCamera {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: flex;
    border: 2px solid #FFF;
    font-size: 16px;
    cursor: pointer;
    top: 0;
    right: 0;
    background-color: #c5ccd4;
    z-index: 10;
}

@media (max-width: 640px) {
    .line {
        height: 55px !important;
    }

    .flex-xs>div {
        display: flex;
    }
}
</style>
