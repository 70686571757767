<template>
    <section class="body-sign">
        <div class="center-sign">
            <a href="/" class="logo pull-left">
                <img src="/images/logo.png" height="54" alt="ViralTrend &dash; Go Viral" />
            </a>

            <div class="panel panel-sign" id="s1" v-if="rStage == 1">
                <div class="panel-title-sign mt-xl text-right">
                    <h2 class="title text-uppercase text-bold m-none"><i class="fa fa-user mr-xs"></i> Register</h2>
                </div>
                <div class="panel-body">

                    <form @submit.prevent='findUserEmail' method="post">
                        <label>Legal Names: </label>
                        <div class="form-group mb-lg">
                            <div class="col-sm-6 viral-col-6-1">
                                <div class="input-group">
                                    <span class="input-group-addon viral-input-addon">
                                        <i class="fa fa-user"></i>
                                    </span>
                                    <input type="text" v-model="first_name" name="first_name" class="form-control input-lg" placeholder="First Name" required />
                                </div>
                            </div>
                            <div class="col-sm-6 viral-col-6-2">
                                <div class="input-group">
                                    <span class="input-group-addon viral-input-addon">
                                        <i class="fa fa-user"></i>
                                    </span>
                                    <input type="text" v-model="last_name" name="last_name" class="form-control input-lg" placeholder="Last Name" required />
                                </div>
                            </div>
                        </div>

                        <div class="form-group mb-lg">
                            <label>Email: </label>
                            <div class="col-sm-12 viral-col">
                                <div class="input-group">
                                    <span class="input-group-addon viral-input-addon">
                                        <i class="fa fa-envelope"></i>
                                    </span>
                                    <input type="email" v-model="email" name="email" class="form-control input-lg" placeholder="E-mail Address" required />
                                </div>
                            </div>

                        </div>
                        <div class="alert alert-warning viral-form-alert hidden">
                            <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                            <strong>This email is already in use. <span> <a href="/login">Want to log in?</a> </span></strong>
                        </div>
                        <div class="alert alert-warning viral-form-alert hidden">
                            <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                            <strong> Oops! Email not verified. <span> <a href="/email_verify">Verify your Email?</a> </span></strong>
                        </div>
                        <div class="row text-center viral-reg-btn-div">
                            <button type="submit" class="mb-xs mt-xs mr-xs btn btn-lg btn-primary viral-btn text-center">Get Started</button>
                        </div>

                        <p class="text-center">
                            Already have an account? <a href="/login">Sign In!</a>
                        </p>

                    </form>

                </div>
            </div>

            <div class="panel panel-sign" id="s2" style="display:nonee" v-if="rStage == 2">
                <div class="panel-title-sign mt-xl text-right">
                    <h2 class="title text-uppercase text-bold m-none"><i class="fa fa-certificate mr-xs"></i>Account</h2>
                    <p class="text-lowercase text-bold m-none viral-reg-user">{{ email }}</p>

                </div>

                <div class="panel-body">
                    <form id="selects-form" @submit.prevent="makeRegister" method="post">
                        <div class="form-group  mb-lg">
                            <div class="col-sm-12 viral-col">
                                <div class="input-group">
                                    <span class="input-group-addon viral-input-addon">
                                        <i class="fa fa-globe"></i>
                                    </span>
                                    <select data-plugin-selectTwo class="form-control populate input-lg viral-select" name="countries" v-model="country">

                                        <option value="Ghana">Ghana</option>
                                        <option value="Kenya">Kenya</option>
                                        <option value="Nigeria">Nigeria</option>
                                    </select>

                                </div>

                            </div>
                        </div>
                        <div class="form-group  mb-lg">
                            <div class="col-sm-12 viral-col">
                                <div class="input-group">
                                    <span class="input-group-addon viral-input-addon">
                                        <i class="fa fa-flag"></i>
                                    </span>
                                    <select data-plugin-selectTwo class="form-control populate input-lg viral-select" name="state-select" id="state-select" required v-model="state">
                                        <option value="" selected="selected">- Select State -</option>
                                        <template v-if="country == 'Nigeria'">
                                            <option value="Abuja FCT">Abuja FCT</option>
                                            <option value="Abia">Abia</option>
                                            <option value="Adamawa">Adamawa</option>
                                            <option value="Akwa Ibom">Akwa Ibom</option>
                                            <option value="Anambra">Anambra</option>
                                            <option value="Bauchi">Bauchi</option>
                                            <option value="Bayelsa">Bayelsa</option>
                                            <option value="Benue">Benue</option>
                                            <option value="Borno">Borno</option>
                                            <option value="Cross River">Cross River</option>
                                            <option value="Delta">Delta</option>
                                            <option value="Ebonyi">Ebonyi</option>
                                            <option value="Edo">Edo</option>
                                            <option value="Ekiti">Ekiti</option>
                                            <option value="Enugu">Enugu</option>
                                            <option value="Gombe">Gombe</option>
                                            <option value="Imo">Imo</option>
                                            <option value="Jigawa">Jigawa</option>
                                            <option value="Kaduna">Kaduna</option>
                                            <option value="Kano">Kano</option>
                                            <option value="Katsina">Katsina</option>
                                            <option value="Kebbi">Kebbi</option>
                                            <option value="Kogi">Kogi</option>
                                            <option value="Kwara">Kwara</option>
                                            <option value="Lagos">Lagos</option>
                                            <option value="Nassarawa">Nassarawa</option>
                                            <option value="Niger">Niger</option>
                                            <option value="Ogun">Ogun</option>
                                            <option value="Ondo">Ondo</option>
                                            <option value="Osun">Osun</option>
                                            <option value="Oyo">Oyo</option>
                                            <option value="Plateau">Plateau</option>
                                            <option value="Rivers">Rivers</option>
                                            <option value="Sokoto">Sokoto</option>
                                            <option value="Taraba">Taraba</option>
                                            <option value="Yobe">Yobe</option>
                                            <option value="Zamfara">Zamfara</option>
                                        </template>
                                        <template v-if="country == 'Ghana'">
                                            <option>Greater Accra</option>
                                            <option>Ashanti</option>
                                            <option>Volta</option>
                                            <option>Western</option>
                                            <option>Northern</option>
                                            <option>Central</option>
                                            <option>Upper East</option>
                                            <option>Eastern</option>
                                            <option>Upper West</option>
                                            <option>Brong-Ahafo</option>
                                        </template>
                                        <template v-if="country == 'Kenya'">
                                            <option>Bomet</option>
                                            <option>Bungoma</option>
                                            <option>Busia</option>
                                            <option>Elgeyo Marakwet</option>
                                            <option>Embu</option>
                                            <option>Garissa</option>
                                            <option>Homa Bay</option>
                                            <option>Isiolo</option>
                                            <option>Kajiado</option>
                                            <option>Kakamega</option>
                                            <option>Kericho</option>
                                            <option>Kiambu</option>
                                            <option>Kilifi</option>
                                            <option>Kirinyaga</option>
                                            <option>Kisii</option>
                                            <option>Kisumu</option>
                                            <option>Kitui</option>
                                            <option>Kwale</option>
                                            <option>Laikipia</option>
                                            <option>Lamu</option>
                                            <option>Machakos</option>
                                            <option>Makueni</option>
                                            <option>Mandera</option>
                                            <option>Meru</option>
                                            <option>Migori</option>
                                            <option>Marsabit</option>
                                            <option>Mombasa</option>
                                            <option>Muranga</option>
                                            <option>Nairobi</option>
                                            <option>Nakuru</option>
                                            <option>Nandi</option>
                                            <option>Narok</option>
                                            <option>Nyamira</option>
                                            <option>Nyandarua</option>
                                            <option>Nyeri</option>
                                            <option>Samburu</option>
                                            <option>Siaya</option>
                                            <option>Taita Taveta</option>
                                            <option>Tana River</option>
                                            <option>Tharaka Nithi</option>
                                            <option>Trans Nzoia</option>
                                            <option>Turkana</option>
                                            <option>Uasin Gishu</option>
                                            <option>Vihiga</option>
                                            <option>Wajir</option>
                                            <option>West Pokot</option>
                                        </template>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-lg">
                            <div class="col-sm-12 viral-col">
                                <div class="input-group">
                                    <span class="input-group-addon viral-input-addon">
                                        <i class="fa fa-lock"></i>
                                    </span>
                                    <input type="password" v-model="password" name="password" class="form-control input-lg" placeholder="Create a password" required />
                                </div>
                            </div>

                        </div>
                        <div class="form-group mb-lg">
                            <div class="col-sm-12 viral-col">
                                <div class="input-group">
                                    <span class="input-group-addon viral-input-addon">
                                        <i class="fa fa-shield"></i>
                                    </span>
                                    <input type="password" v-model="repeat_password" name="confirm-password" class="form-control input-lg" placeholder="Confirm Password" required />
                                </div>
                            </div>

                        </div>
                        <div class="text-center">
                            <h4><strong>I want to:</strong></h4>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 viral-col">
                                <div class="tabs viral-tabs">
                                    <ul class="nav nav-tabs nav-justified viral-reg-tabs">
                                        <li class="active" >
                                            <a href="#promoter-account" data-toggle="tab" class="text-center">Promote and Earn</a>
                                        </li>
                                    </ul>
                                    <div class="tab-content viral-tab-content">
                                        <div id="advertiser-account" class="tab-pane @if($section == 'Advertiser') active @endif">
                                            <div class="form-group mb-lg">
                                                <div class="col-sm-12 ">
                                                    <div class="checkbox-custom chekbox-primary">
                                                        <input v-model="terms" id="for-terms-conditionsA" value="terms-conditions" type="checkbox" name="for[]" required  />
                                                        <label for="for-terms-conditionsA"> Yes, I understand and agree to the ViralTrend <span><a href="/terms">Terms of Service</a></span>, including the <span><a href="/agreement">User Agreement</a></span> and <span><a href="/privacy">Privacy Policy.</a></span></label>
                                                    </div>
                                                    <label class="error" for="for[]"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="promoter-account" class="tab-pane active">
                                            <div class="form-group mb-lg">
                                                <div class="col-sm-12">
                                                    <div class="input-group">
                                                        <span class="input-group-addon viral-input-addon">
                                                            <i class="fa fa-smile-o"></i>
                                                        </span>
                                                        <input type="text" v-model="username" name="username" class="form-control" placeholder="Username" required />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row text-center viral-reg-btn-div">
                            <button id="register" type="submit" class="mb-xs mt-xs mr-xs btn btn-lg btn-primary viral-btn text-center" >
                                Register
                            </button>
                        </div>

                        <p class="text-center">
                            Already have an account? <a href="/login">Sign In!</a>
                        </p>

                    </form>
                </div>
            </div>

            <div class="panel panel-sign" id="s1" v-if="rStage == 3">
                <div class="panel-title-sign mt-xl text-right">
                    <h2 class="title text-uppercase text-bold m-none"><i class="fa fa-user mr-xs"></i> Register</h2>
                </div>
                <div class="panel-body">

                    <form @submit.prevent='activateAccount' method="post">
                        <div class="form-group mb-lg">
                            <label>Code: </label>
                            <div class="col-sm-12 viral-col">
                                <div class="input-group">
                                    <span class="input-group-addon viral-input-addon">
                                        <i class="fa fa-envelope"></i>
                                    </span>
                                    <input type="text" v-model="code" name="email" class="form-control input-lg" placeholder="Code sent to email" required />
                                </div>
                            </div>

                        </div>

                        <p class="text-center">
                            Already have an account? <a href="/login">Sign In!</a>
                        </p>

                    </form>

                </div>
            </div>
            <input type="text" id="referral" class="form-control input-lg" placeholder="Referral Key" :value="referral" style="display:none" />

            <p class="text-center text-muted mt-md mb-md">
                &copy; Copyright {{ copyright() }}, 
                <span>
                    <a href="https://viraltrend.org">ViralTrend</a>
                </span>
            </p>
        </div>
    </section>
</template>

<script>
import { ApiService } from '@/services/api.service'

export default {
    name: "RegisterPage",
    setup () {
    },
    data() {
        return {
            id: "",
            email: "",
            password: "",
            repeat_password: "",
            first_name: "",
            last_name: "",
            username: "",
            gender: "",
            phone: "",
            date_of_birth: "",
            country: "",
            state: "",
            referral: "",
            terms: "",
            code: "",
            error: "",
            account_type: 'promoter',
            rStage: 1,
            countryName: true,
            regionName: true,
        };
    },
    components: {
    },
    mounted(){
        let params = this.$route.params
        this.referral = params['referral_id']
        let accessToken = this.$store.getters['auth/accessToken']
        if(accessToken){
            location.href = '/dashboard'
        }
    },
    methods: {
        async findUserEmail() {
            if (!this.email) {
                return this.$store.commit("toast/Notify", {
                type: "warning",
                message: "email is required",
                });
            }
            $("button").attr('disabled', true)
            let apiResponse = await ApiService.FindUserEmail(
                this.email
            );
            if (apiResponse["status"] != "success") {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            }else{
                this.rStage = 2
            }
            $("button").attr('disabled', false)
        },
        async makeRegister() {
            if (!this.email) {
                return this.$store.commit("toast/Notify", {
                type: "warning",
                message: "email is required",
                });
            }
            if (!this.password) {
                return this.$store.commit("toast/Notify", {
                type: "warning",
                message: "password is required",
                });
            }
            if (!this.terms) {
                return this.$store.commit("toast/Notify", {
                type: "warning",
                message: "please check the terms and conditions box",
                });
            }
            $("button").attr('disabled', true)
            this.toggleProgress('show')
            let apiResponse = await ApiService.RegisterUser(
                this.email,
                this.password,
                this.repeat_password,
                this.first_name,
                this.last_name,
                this.username,
                this.gender,
                this.phone,
                this.date_of_birth,
                this.country,
                this.state,
                this.referral
            );
            // console.log(apiResponse);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {
            }else{
                // this.$router.push("/register-success");
                setTimeout(() => {
                    location.href = '/login'
                }, 1300);
            }
            this.toggleProgress('hide')
            $("button").attr('disabled', false)
        },
        async activateAccount() {
            if (!this.email) {
                return this.$store.commit("toast/Notify", {
                type: "warning",
                message: "email is required",
                });
            }
            $("button").attr('disabled', true)
            let apiResponse = await ApiService.ActivateAccount(
                this.code,
                this.email
            );
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {
            }else{
                setTimeout(() => {
                    location.href = '/login'
                }, 1300);
            }
            $("button").attr('disabled', false)
        },
        async setDob(a){
            // console.log(":::::::::",a)
            this.date_of_birth = a
        }
    },
};
</script>

<style lang="scss" scoped>
</style>
