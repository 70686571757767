<template>
    <section class="body-sign">
        <div class="center-sign">
            <a href="/" class="logo pull-left">
                <img src="/images/logo.png" height="54" alt="ViralTrend &dash; Go Viral" />
            </a>
  
            <div class="panel panel-sign">
                <div class="panel-title-sign mt-xl text-right">
                    <h2 class="title text-uppercase text-bold m-none"><i class="fa fa-user mr-xs"></i> Log In</h2>
                </div>
                <div class="panel-body">
                    <div id="" >
                        <div class="form-group mb-lg">
                            <div class="col-sm-12 viral-col">
                                <div class="input-group">
                                    <span class="input-group-addon viral-input-addon">
                                        <i class="fa fa-envelope"></i>
                                    </span>
                                    <input type="email" name="email" v-model="email" class="form-control input-lg" placeholder="E-mail Address" required/>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-lg">
                            <div class="col-sm-12 viral-col">
                                <div class="input-group">
                                    <span class="input-group-addon viral-input-addon">
                                        <i class="fa fa-key"></i>
                                    </span>
                                    <input type="password" v-model="password" name="password" class="form-control input-lg" placeholder="Password" required/>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-8">
                                <div class="checkbox-custom checkbox-default">
                                    <input id="RememberMe" name="rememberme" type="checkbox"/>
                                    <label for="RememberMe">Keep me logged in</label>
                                </div>
                            </div>
                            <div class="col-sm-4 text-right">
                                <a :href="baseUrl+'reset-password'">Forgot password?</a>
                            </div>
                        </div>
                        <div class="alert alert-warning viral-form-alert" id="noEmail">
                            <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                            <strong> 
                                Oops! Email does not exist. 
                                <span> <a :href="baseUrl+'register.html'">Want to Register?</a> </span>
                            </strong> 
                        </div>
                        <div class="alert alert-warning viral-form-alert" id="noVerification">
                            <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                            <strong> 
                                Oops! Email not verified. 
                                <span> <a :href="baseUrl+'activate-account'">Verify your Email?</a> </span>
                            </strong> 
                        </div>
                        <div class="row text-center viral-reg-btn-div" @click="makeLogin">
                            <button type="submit" class="mb-xs mt-xs mr-xs btn btn-lg btn-primary viral-btn text-center">
                                Continue
                            </button>
                        </div>
  
                        <p class="text-center" >
                            Don't have an account? <a :href="baseUrl+'register'">Register!</a>
                        </p>
                                
                    </div>
                </div>
  
  
            </div>
  
            <p class="text-center text-muted mt-md mb-md" >
                &copy; Copyright {{ copyright() }}, 
                <span>
                    <a href="https://viraltrend.org">ViralTrend</a>
                </span>
            </p>
        </div>
    </section>
</template>

<script>
import { ApiService } from '@/services/api.service'

export default {
  name: 'RequestAccountActivation',
  data () {
    return {
      email: '',
      code: '',
      password: '',
      repeat_password: '',
      stage: '1',
      error: ''
    }
  },
  components: {
  },
  methods: {
    async requestAccountActivation () {
        if(!this.email){
            return this.$store.commit('toast/Notify', { type: 'warning', message: 'email is required' })
        }
        let apiResponse = await ApiService.RequestAccountActivation(this.email)
        // console.log(apiResponse)
        this.$store.commit('toast/Notify', { type: apiResponse['status'], message: apiResponse['message'] })
        if(apiResponse['status'] == 'success'){
        }
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
