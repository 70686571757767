export default {
    currentUser: state => {
        let currentUser = localStorage.getItem('currentUser')
        if (currentUser) {
            if (currentUser != 'undefined') {
                currentUser = JSON.parse(currentUser)
            }
        }
        return currentUser;
    },
    currentUserSubscription: state => {
        let currentUserSubscription = localStorage.getItem('currentUserSubscription')
        if (currentUserSubscription) {
            if (currentUserSubscription != 'undefined') {
                currentUserSubscription = JSON.parse(currentUserSubscription)
            }
        }
        return currentUserSubscription;
    },
}