export default {
    SET_CURRENT_USER(state) {
        let currentUser = localStorage.getItem('currentUser')
        if (currentUser) {
            currentUser = JSON.parse(currentUser)
            state.currentUser = currentUser
        }
    },
    SAVE_CURRENT_USER(state, currentUser) {
        state.currentUser = currentUser
            // console.log(currentUser, 'SAVE_CURRENT_USER')
        localStorage.setItem('currentUser', JSON.stringify(currentUser))
    },
    CLEAR_CURRENT_USER(state) {
        localStorage.clear('currentUser')
    },
    SET_CURRENT_USER_SUBSCRIPTION(state) {
        let currentUserSubscription = localStorage.getItem('currentUserSubscription')
        if (currentUserSubscription) {
            currentUserSubscription = JSON.parse(currentUserSubscription)
            state.currentUserSubscription = currentUserSubscription
        }
    },
    SAVE_CURRENT_USER_SUBSCRIPTION(state, currentUserSubscription) {
        state.currentUserSubscription = currentUserSubscription
        console.log(currentUserSubscription, 'SAVE_CURRENT_USER_SUBSCRIPTION')
        localStorage.setItem('currentUserSubscription', JSON.stringify(currentUserSubscription))
    },
    CLEAR_CURRENT_USER_SUBSCRIPTION(state) {
        localStorage.clear('currentUserSubscription')
    }
}