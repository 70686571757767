import Vue from 'vue'
import Router from 'vue-router'

import { initCurrentUserStateMiddleware, checkAccessMiddleware, setPageTitleMiddleware, scrollToTop } from './middlewares'
import { routes } from './routes'

Vue.use(Router)

const router = new Router({
    linkActiveClass: 'is-active',
    mode: 'history',
    routes
})

router.beforeEach(initCurrentUserStateMiddleware)
router.beforeEach(checkAccessMiddleware)
router.beforeEach(setPageTitleMiddleware)
router.beforeEach(scrollToTop)

export default router